import * as React from 'react';
import { Modal, Select, Form } from 'antd';

import { Work } from '../types/apiResponse/works.response';
import { Client } from '../types/apiResponse/clients.response';
import { DailyReport } from '../types/apiResponse/dailyReports.response';

const { Option } = Select;

export interface Props {
  works: Work[];
  clients: Client[];
  workId: number;
  clientId?: number;
  dailyReportProductId?: number;
  dailyReport?: DailyReport;
  visibleNew: boolean;
  visibleEdit: boolean;
  isNewDuplicated: boolean;
  isEditDuplicated: boolean;
  onChangeWorks: (value: string) => void;
  onChangeClients: (value?: string) => void;
  onChangeDailyReportProducts: (value?: string) => void;
  onCannelAdd: () => void;
  onCannelEdit: () => void;
  handleOkAdd: () => void;
  handleOkEdit: () => void;
}

const DailyReportAddEditWorkModal: React.SFC<Props> = props => {
  const {
    works,
    clients,
    workId,
    clientId,
    dailyReportProductId,
    dailyReport,
    visibleNew,
    visibleEdit,
    isNewDuplicated,
    isEditDuplicated,
    onChangeWorks,
    onChangeClients,
    onChangeDailyReportProducts,
    onCannelAdd,
    onCannelEdit,
    handleOkAdd,
    handleOkEdit,
  } = props;
  return (
    <>
      <Modal
        title="日報:作業の追加"
        visible={visibleNew}
        onOk={handleOkAdd}
        onCancel={onCannelAdd}
        cancelText="キャンセル"
        okButtonProps={{ disabled: !workId || isNewDuplicated }}
      >
        <Form>
          <Form.Item
            validateStatus={isNewDuplicated ? 'error' : undefined}
            help={
              isNewDuplicated
                ? '既に登録されている内容は登録できません'
                : undefined
            }
          >
            <Select
              placeholder="作業"
              value={workId ? `${workId}` : undefined}
              style={{ width: '100%' }}
              onChange={onChangeWorks}
            >
              {works
                .filter(v => !!v.visibleFlag)
                .map(v => (
                  <Option key={v.workId} value={`${v.workId}`}>
                    {v.clientId
                      ? `${v.workName} (${v.clientName})`
                      : v.workName}
                  </Option>
                ))}
            </Select>
            <Select
              placeholder="取引先"
              value={clientId ? `${clientId}` : undefined}
              style={{ width: '100%' }}
              onChange={onChangeClients}
            >
              {clients
                .map(v => (
                  <Option key={v.clientId} value={`${v.clientId}`}>
                    {v.clientName}
                  </Option>
                ))
                .concat([
                  <Option
                    key={0}
                    value={undefined}
                    style={{ color: '#bfbfbf' }}
                  >
                    選択しない
                  </Option>,
                ])}
            </Select>
            <Select
              placeholder="製品"
              value={
                dailyReportProductId ? `${dailyReportProductId}` : undefined
              }
              style={{ width: '100%' }}
              onChange={onChangeDailyReportProducts}
            >
              {dailyReport &&
                dailyReport.products
                  .filter(v => !!v.visibleFlag)
                  .map(v => (
                    <Option
                      key={v.dailyReportProductId}
                      value={`${v.dailyReportProductId}`}
                    >
                      {v.productName}
                    </Option>
                  ))
                  .concat([
                    <Option
                      key={0}
                      value={undefined}
                      style={{ color: '#bfbfbf' }}
                    >
                      選択しない
                    </Option>,
                  ])}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      {/* 日報:作業の編集 */}
      <Modal
        title="日報:作業の編集"
        visible={visibleEdit}
        onOk={handleOkEdit}
        onCancel={onCannelEdit}
        cancelText="キャンセル"
        okButtonProps={{ disabled: !workId || isEditDuplicated }}
      >
        <Form>
          <Form.Item
            validateStatus={isEditDuplicated ? 'error' : undefined}
            help={
              isEditDuplicated
                ? '既に登録されている内容は登録できません'
                : undefined
            }
          >
            <Select
              placeholder="作業"
              value={workId ? `${workId}` : undefined}
              style={{ width: '100%' }}
              onChange={onChangeWorks}
            >
              {works
                .filter(v => !!v.visibleFlag)
                .map(v => (
                  <Option key={v.workId} value={`${v.workId}`}>
                    {v.clientId
                      ? `${v.workName} (${v.clientName})`
                      : v.workName}
                  </Option>
                ))}
            </Select>
            <Select
              placeholder="取引先"
              value={clientId ? `${clientId}` : undefined}
              style={{ width: '100%' }}
              onChange={onChangeClients}
            >
              {clients
                .filter(v => !!v.visibleFlag)
                .map(v => (
                  <Option key={v.clientId} value={`${v.clientId}`}>
                    {v.clientName}
                  </Option>
                ))
                .concat([
                  <Option
                    key={0}
                    value={undefined}
                    style={{ color: '#bfbfbf' }}
                  >
                    選択しない
                  </Option>,
                ])}
            </Select>
            <Select
              placeholder="製品"
              value={
                dailyReportProductId ? `${dailyReportProductId}` : undefined
              }
              style={{ width: '100%' }}
              onChange={onChangeDailyReportProducts}
            >
              {dailyReport &&
                dailyReport.products
                  .filter(v => !!v.visibleFlag)
                  .map(v => (
                    <Option
                      key={v.dailyReportProductId}
                      value={`${v.dailyReportProductId}`}
                    >
                      {`${v.productName} / ${v.unitPrice}円`}
                    </Option>
                  ))
                  .concat([
                    <Option
                      key={0}
                      value={undefined}
                      style={{ color: '#bfbfbf' }}
                    >
                      選択しない
                    </Option>,
                  ])}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DailyReportAddEditWorkModal;
