import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { AxiosError } from 'axios';
import {
  Button,
  Table,
  Divider,
  Icon,
  Modal,
  Select,
  Form,
  Switch,
} from 'antd';

import { CustomInput as Input } from './components/CustomAntd';

import { PostPutWorksReq } from './types/apiRequest/works.request';
import { GetWorksRes, Work } from './types/apiResponse/works.response';
import { GetClientsRes, Client } from './types/apiResponse/clients.response';
import {
  GetWorkGroupsRes,
  WorkGroup,
} from './types/apiResponse/workGroups.response';

import httpUtils from './utils/httpUtils';
import { ApplicationState } from './reducers';

const { Option } = Select;

type E = React.ChangeEvent<HTMLInputElement>;

const mapStateToProps = (state: ApplicationState) => ({
  officeId: state.baseReducer.selectedOfficeId,
});

const mapDispatchToProps = () => ({});

type Props = RouteComponentProps<{}> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface State {
  works: Work[];
  clients: Client[];
  workGroups: WorkGroup[];
  visibleNew: boolean;
  visibleEdit: boolean;
  workId: number;
  workName: string;
  clientId?: number;
  workGroupId?: number;
}

const initialState = {
  works: [],
  clients: [],
  workGroups: [],
  visibleNew: false,
  visibleEdit: false,
  workId: 0,
  workName: '',
  clientId: undefined,
  workGroupId: undefined,
  visibleFlag: 1,
};

class AdminWork extends React.PureComponent<Props, State> {
  columns = [
    {
      title: '作業名',
      dataIndex: 'workName',
      key: 'workName',
      defaultSortOrder: 'ascend',
      sorter: (a: Work, b: Work) => (a.workName < b.workName ? -1 : 1),
    },
    {
      title: '取引先名',
      dataIndex: 'clientName',
      key: 'clientName',
      sorter: (a: Work, b: Work) =>
        (a.clientName || '') < (b.clientName || '') ? -1 : 1,
    },
    {
      title: '作業グループ名',
      dataIndex: 'workGroupName',
      key: 'workGruopName',
      sorter: (a: WorkGroup, b: WorkGroup) =>
        (a.workGroupName || '') < (b.workGroupName || '') ? -1 : 1,
    },
    {
      title: '表示 / 非表示',
      render: (record: Work) => (
        <Switch
          checked={!!record.visibleFlag}
          onChange={() => {
            this.handleSwitch(record);
          }}
        />
      ),
    },
    {
      title: '編集 / 削除',
      dataIndex: '',
      key: 'x',
      // @ts-ignore
      render: (record: Work) => (
        <span>
          <Icon
            type="edit"
            onClick={() => {
              this.setState({
                workId: record.workId,
                workName: record.workName,
                clientId: record.clientId,
                workGroupId: record.workGroupId,
                visibleEdit: true,
              });
            }}
          />
          <Divider type="vertical" />
          <Icon
            type="delete"
            onClick={() => {
              const handleDelete = this.handleDelete;
              Modal.confirm({
                title: 'このデータを削除しますか？',
                content: '削除すると、元に戻せません。',
                cancelText: 'キャンセル',
                onOk() {
                  handleDelete(record.workId);
                },
                onCancel() {},
              });
            }}
          />
        </span>
      ),
    },
  ];

  state: State = initialState;

  handleFetch = async () => {
    const { officeId } = this.props;
    try {
      const [res1, res2, res3] = await Promise.all([
        httpUtils.get(`/works`, {
          params: { officeId },
        }),
        httpUtils.get(`/clients`, {
          params: { officeId },
        }),
        httpUtils.get(`/workGroups`, {
          params: { officeId },
        }),
      ]);
      const works: GetWorksRes = res1.data;
      const clients: GetClientsRes = res2.data;
      const workGroups: GetWorkGroupsRes = res3.data;
      this.setState({ works, clients, workGroups });
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePost = async () => {
    const { officeId } = this.props;
    const { workName, clientId, workGroupId } = this.state;
    const req: PostPutWorksReq = {
      officeId,
      workName,
      clientId,
      workGroupId,
    };
    try {
      await httpUtils.post(`/works`, req);
      this.setState({ ...initialState });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePut = async () => {
    const { officeId } = this.props;
    const { workId, workName, clientId, workGroupId } = this.state;
    const req: PostPutWorksReq = {
      officeId,
      workName,
      clientId,
      workGroupId,
    };
    try {
      await httpUtils.put(`/works/${workId}`, req);
      this.setState({ ...initialState });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleDelete = async (id: number) => {
    const { officeId } = this.props;
    try {
      await httpUtils.delete(`/works/${id}`, {
        data: {
          officeId,
        },
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleSwitch = async (record: Work) => {
    const { officeId } = this.props;
    const { workId } = record;
    try {
      await httpUtils.put(`/works/${workId}`, {
        ...record,
        officeId,
        visibleFlag: record.visibleFlag === 1 ? 0 : 1,
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  onChangeWorkName = (e: E) => {
    this.setState({ workName: e.target.value });
  };

  onChangeClients = (value?: string) => {
    this.setState({
      clientId: value ? parseInt(value, 10) : initialState.clientId,
    });
  };

  onChangeWorkGroups = (value?: string) => {
    this.setState({
      workGroupId: value ? parseInt(value, 10) : initialState.workGroupId,
    });
  };

  componentDidMount() {
    const { officeId } = this.props;
    // ログインに戻す
    if (!officeId) {
      this.props.history.push('/login');
      return;
    }
    this.handleFetch();
  }

  render() {
    const {
      works,
      clients,
      workGroups,
      workId,
      workName,
      clientId,
      workGroupId,
      visibleNew,
      visibleEdit,
    } = this.state;

    const isNewDuplicated = !!works
      // TODO : レスポンスがnullになるのでとりあえずこれで対応
      .map(x => ({ ...x, clientId: x.clientId || undefined }))
      .filter(x => x.workName === workName && x.clientId === clientId).length;

    const isEditDuplicated = !!works
      // TODO : レスポンスがnullになるのでとりあえずこれで対応
      .map(x => ({ ...x, clientId: x.clientId || undefined }))
      .filter(x => x.workId !== workId)
      .filter(x => x.workName === workName && x.clientId === clientId).length;

    return (
      <>
        <Button
          onClick={() => {
            this.setState({ visibleNew: true });
          }}
          type="primary"
          style={{ marginBottom: 16 }}
        >
          追加
        </Button>
        <Table
          // @ts-ignore
          columns={this.columns}
          dataSource={works}
          rowKey="workId"
        />
        <Modal
          title="作業を追加"
          visible={visibleNew}
          onOk={() => this.handlePost()}
          onCancel={() =>
            this.setState({ ...initialState, works, clients, workGroups })
          }
          cancelText="キャンセル"
          okButtonProps={{ disabled: !workName || isNewDuplicated }}
        >
          <Form>
            <Form.Item
              validateStatus={isNewDuplicated ? 'error' : undefined}
              help={
                isNewDuplicated
                  ? '既に登録されている内容では登録できません'
                  : undefined
              }
            >
              <Input
                placeholder="作業名"
                value={workName}
                onChange={this.onChangeWorkName}
              />
              <Select
                placeholder="取引先"
                value={clientId ? `${clientId}` : undefined}
                style={{ width: '100%' }}
                onChange={this.onChangeClients}
              >
                {clients
                  .map(v => (
                    <Option key={v.clientId} value={`${v.clientId}`}>
                      {v.clientName}
                    </Option>
                  ))
                  .concat([
                    <Option
                      key={0}
                      value={undefined}
                      style={{ color: '#bfbfbf' }}
                    >
                      選択しない
                    </Option>,
                  ])}
              </Select>
              <Select
                placeholder="作業グループ"
                value={workGroupId ? `${workGroupId}` : undefined}
                style={{ width: '100%' }}
                onChange={this.onChangeWorkGroups}
              >
                {workGroups
                  .filter(v => !!v.visibleFlag)
                  .map(v => (
                    <Option key={v.workGroupId} value={`${v.workGroupId}`}>
                      {v.workGroupName}
                    </Option>
                  ))
                  .concat([
                    <Option
                      key={0}
                      value={undefined}
                      style={{ color: '#bfbfbf' }}
                    >
                      選択しない
                    </Option>,
                  ])}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="作業を編集"
          visible={visibleEdit}
          onOk={() => this.handlePut()}
          onCancel={() =>
            this.setState({ ...initialState, works, clients, workGroups })
          }
          cancelText="キャンセル"
          okButtonProps={{ disabled: !workName || isEditDuplicated }}
        >
          <Form>
            <Form.Item
              validateStatus={isEditDuplicated ? 'error' : undefined}
              help={
                isEditDuplicated
                  ? '既に登録されている名前は使用できません'
                  : undefined
              }
            >
              <Input
                placeholder="作業名"
                value={workName}
                onChange={this.onChangeWorkName}
              />
              <Select
                placeholder="取引先"
                value={clientId ? `${clientId}` : undefined}
                style={{ width: '100%' }}
                onChange={this.onChangeClients}
              >
                {clients
                  .map(v => (
                    <Option key={v.clientId} value={`${v.clientId}`}>
                      {v.clientName}
                    </Option>
                  ))
                  .concat([
                    <Option
                      key={0}
                      value={undefined}
                      style={{ color: '#bfbfbf' }}
                    >
                      選択しない
                    </Option>,
                  ])}
              </Select>
              <Select
                placeholder="作業グループ"
                value={workGroupId ? `${workGroupId}` : undefined}
                style={{ width: '100%' }}
                onChange={this.onChangeWorkGroups}
              >
                {workGroups
                  .filter(v => !!v.visibleFlag)
                  .map(v => (
                    <Option key={v.workGroupId} value={`${v.workGroupId}`}>
                      {v.workGroupName}
                    </Option>
                  ))
                  .concat([
                    <Option
                      key={0}
                      value={undefined}
                      style={{ color: '#bfbfbf' }}
                    >
                      選択しない
                    </Option>,
                  ])}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminWork);
