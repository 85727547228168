import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Form, Icon, Button, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';

import { CustomInput as Input } from './components/CustomAntd';

import * as baseAction from './actions/baseAction';

import awsUtils from './utils/awsUtils';
import httpUtils from './utils/httpUtils';
import { GetUsersMeRes, User } from './types/apiResponse/users.response';

type E = React.ChangeEvent<HTMLInputElement>;

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setUser: (user: User) => dispatch(baseAction.setUser(user)),
  initState: () => dispatch(baseAction.initState()),
});

type Props = RouteComponentProps<{}> &
  FormComponentProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const hasErrors = (fieldsError: any) => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
};

class Login extends React.PureComponent<Props, {}> {
  componentDidMount() {
    // 念のためlocalStorageをクリア
    localStorage.clear();
    const { initState } = this.props;
    initState();
  }

  handleLogin = (e: E) => {
    e.preventDefault();
    // console.log(this.props);
    this.props.form.validateFields(async (err, values) => {
      const username = values.userName;
      const password = values.password;
      if (!err) {
        try {
          await awsUtils.signIn(username, password);
          const res = await httpUtils.get('/users/me');
          const user: GetUsersMeRes = res.data;
          this.props.setUser(user);
          // TODO : 一旦これで
          this.props.history.push('/activities');
          message.success('ログインしました');
          return;
        } catch (err) {
          // console.log(err);
          message.error('ログインに失敗しました');
        }
      }
    });
  };

  render() {
    // console.log(this.state);
    // console.log(this.props);
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = this.props.form;

    const userNameError =
      isFieldTouched('userName') && getFieldError('userName');
    const passwordError =
      isFieldTouched('password') && getFieldError('password');

    return (
      <>
        <Form layout="inline" onSubmit={this.handleLogin}>
          <Form.Item
            validateStatus={userNameError ? 'error' : ''}
            help={userNameError || ''}
          >
            {getFieldDecorator('userName', {
              rules: [
                { required: true, message: 'ユーザー名を入力してください' },
              ],
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="ユーザー名"
              />
            )}
          </Form.Item>
          <Form.Item
            validateStatus={passwordError ? 'error' : ''}
            help={passwordError || ''}
          >
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'パスワードを入力してください' },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                placeholder="パスワード"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              ログイン
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'horizontal_login' })(Login));
