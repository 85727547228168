import actionCreatorFactory from 'typescript-fsa';

import { User } from './../types/apiResponse/users.response';

// type Error = {
//   message: string;
// } | null;

const basePrefix = '@@base';
const actionCreator = actionCreatorFactory(basePrefix);

export const setUser = actionCreator<User>('SET_USER');
export const selectOfficeId = actionCreator<number>('SELECT_OFFICE_ID');

export const initState = actionCreator('INIT_STATE');
