import * as React from 'react';
import {
  Route,
  Link,
  Switch,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import { Layout, Menu, Icon, Modal } from 'antd';

import Login from './Login';
import AppHeader from './AppHeader';

import TableActivity from './TableActivity';
// import TablePL from './TablePL';
import TableSale from './TableSale';

import AdminClient from './AdminClient';
import AdminDepartment from './AdminDepartment';
import AdminProduct from './AdminProduct';
import AdminWorkGroup from './AdminWorkGroup';
import AdminWork from './AdminWork';
import AdminWorker from './AdminWorker';
import AdminDailyReport from './AdminDailyReport';

import SettingSystemKbn from './SettingSystemKbn';

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

const confirm = Modal.confirm;

type Props = RouteComponentProps;

const App: React.SFC<Props> = props => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AppHeader />
      <Layout>
        <Sider width={200} style={{ background: '#fff' }}>
          <Menu
            mode="inline"
            // defaultSelectedKeys={['1']}
            selectedKeys={[window.location.pathname]}
            defaultOpenKeys={['sub1', 'sub2', 'sub3', 'sub4']}
            style={{ height: '100%', borderRight: 0 }}
          >
            <SubMenu
              key="sub1"
              title={
                <span>
                  <Icon type="database" />
                  マスタ管理
                </span>
              }
            >
              <Menu.Item key="/clients">
                <Link to="/clients">取引先</Link>
              </Menu.Item>
              <Menu.Item key="/departments">
                <Link to="/departments">部門 / チーム</Link>
              </Menu.Item>
              <Menu.Item key="/products">
                <Link to="/products">製品</Link>
              </Menu.Item>
              <Menu.Item key="/workGroups">
                <Link to="/workGroups">作業グループ</Link>
              </Menu.Item>
              <Menu.Item key="/works">
                <Link to="/works">作業</Link>
              </Menu.Item>
              <Menu.Item key="/workers">
                <Link to="/workers">従業員</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="/dailyReports">
              <Link to="/dailyReports">
                <Icon type="file-text" />
                <span>日報</span>
              </Link>
            </Menu.Item>
            <SubMenu
              key="sub3"
              title={
                <span>
                  <Icon type="pie-chart" />
                  分析
                </span>
              }
            >
              <Menu.Item key="/activities">
                <Link to="/activities">作業実績一覧</Link>
              </Menu.Item>
              {/* <Menu.Item key="/pls">
                <Link to="/pls">コスト集計</Link>
              </Menu.Item> */}
              <Menu.Item key="/sales">
                <Link to="/sales">売上集計</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub4"
              title={
                <span>
                  <Icon type="setting" />
                  設定
                </span>
              }
            >
              <Menu.Item>
                <Link to="/setting/systemKbns">システム区分</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              key="/login"
              onClick={() =>
                confirm({
                  title: 'ログアウトしますか?',
                  cancelText: 'キャンセル',
                  content: 'ログアウトすると再ログインが必要です',
                  onOk() {
                    props.history.push('/login');
                  },
                  // onCancel() {},
                })
              }
            >
              <Icon type="logout" />
              <span>ログアウト</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content style={{ padding: '50px', backgroundColor: '#fff' }}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/activities" component={TableActivity} />
              {/* <Route path="/pls" component={TablePL} /> */}
              <Route path="/sales" component={TableSale} />
              <Route path="/clients" component={AdminClient} />
              <Route path="/departments" component={AdminDepartment} />
              <Route path="/products" component={AdminProduct} />
              <Route path="/workGroups" component={AdminWorkGroup} />
              <Route path="/works" component={AdminWork} />
              <Route path="/workers" component={AdminWorker} />
              <Route path="/dailyReports" component={AdminDailyReport} />
              <Route path="/setting/systemKbns" component={SettingSystemKbn} />
              <Route render={() => <Redirect to="/activities" />} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default App;
