import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { AxiosError } from 'axios';
import { Button, Table, Divider, Icon, Modal, Tag, Form, Switch } from 'antd';

import { CustomInput as Input } from './components/CustomAntd';
import { PostPutDepartmentsReq } from './types/apiRequest/departments.request';
import {
  GetDepartmentsRes,
  Department,
  Team,
} from './types/apiResponse/departments.response';
import httpUtils from './utils/httpUtils';
import { ApplicationState } from './reducers';

type E = React.ChangeEvent<HTMLInputElement>;

const mapStateToProps = (state: ApplicationState) => ({
  officeId: state.baseReducer.selectedOfficeId,
});

const mapDispatchToProps = () => ({});

type Props = RouteComponentProps<{}> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface State {
  departments: Department[];
  visibleNew: boolean;
  visibleEdit: boolean;
  visibleInputTabs: boolean;
  inputValue: string;
  department: Department;
}

const initialState = {
  departments: [],
  visibleNew: false,
  visibleEdit: false,
  visibleInputTabs: false,
  inputValue: '',
  department: {
    departmentId: 0,
    departmentName: '',
    teams: [],
    visibleFlag: 1,
  },
  visibleFlag: 1,
};

class AdminDepartment extends React.PureComponent<Props, State> {
  columns = [
    {
      title: '部門',
      dataIndex: 'departmentName',
      key: 'departmentName',
      defaultSortOrder: 'ascend',
      sorter: (a: Department, b: Department) =>
        a.departmentName < b.departmentName ? -1 : 1,
    },
    {
      title: 'チーム',
      dataIndex: 'teams',
      key: 'teams',
      render: (teams: Team[]) => (
        <span>
          {teams.map(team => {
            return <Tag key={team.teamId}>{team.teamName}</Tag>;
          })}
        </span>
      ),
    },
    {
      title: '表示 / 非表示',
      render: (record: Department) => (
        <Switch
          checked={!!record.visibleFlag}
          onChange={() => {
            this.handleSwitch(record);
          }}
        />
      ),
    },
    {
      title: '編集 / 削除',
      dataIndex: '',
      key: 'x',
      // @ts-ignore
      render: (record: Department) => (
        <span>
          <Icon
            type="edit"
            onClick={() => {
              this.setState({
                department: record,
                visibleEdit: true,
              });
            }}
          />
          <Divider type="vertical" />
          <Icon
            type="delete"
            onClick={() => {
              const handleDelete = this.handleDelete;
              Modal.confirm({
                title: 'このデータを削除しますか？',
                content: '削除すると、元に戻せません。',
                cancelText: 'キャンセル',
                onOk() {
                  handleDelete(record.departmentId);
                },
                onCancel() {},
              });
            }}
          />
        </span>
      ),
    },
  ];

  state: State = initialState;

  handleFetch = async () => {
    const { officeId } = this.props;
    try {
      const res = await httpUtils.get(`/departments`, {
        params: { officeId },
      });
      const departments: GetDepartmentsRes = res.data;
      this.setState({ departments });
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePost = async () => {
    const { officeId } = this.props;
    const { department } = this.state;
    const req: PostPutDepartmentsReq = {
      officeId,
      departmentName: department.departmentName,
      teams: department.teams,
    };
    try {
      await httpUtils.post(`/departments`, req);
      this.setState({ ...initialState });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePut = async () => {
    const { officeId } = this.props;
    const { department } = this.state;
    const req: PostPutDepartmentsReq = {
      officeId,
      departmentName: department.departmentName,
      teams: department.teams,
    };
    try {
      await httpUtils.put(`/departments/${department.departmentId}`, req);
      this.setState({ ...initialState });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleDelete = async (id: number) => {
    const { officeId } = this.props;
    try {
      await httpUtils.delete(`/departments/${id}`, {
        data: {
          officeId,
        },
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleSwitch = async (record: Department) => {
    const { officeId } = this.props;
    const { departmentId } = record;
    try {
      await httpUtils.put(`/departments/${departmentId}`, {
        ...record,
        officeId,
        visibleFlag: record.visibleFlag === 1 ? 0 : 1,
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  onChangeDepartmentName = (e: E) => {
    this.setState({
      department: {
        ...this.state.department,
        departmentName: e.target.value,
      },
    });
  };

  addTeams = () => {
    this.setState({
      department: {
        ...this.state.department,
        teams: [
          ...this.state.department.teams,
          {
            teamId: 0,
            teamName: '',
          },
        ],
      },
    });
  };

  deleteTeams = (index: number) => () => {
    this.setState({
      department: {
        ...this.state.department,
        teams: [...this.state.department.teams.filter((_, i) => i !== index)],
      },
    });
  };

  onChangeTeamName = (index: number) => (e: E) => {
    const teams = this.state.department.teams.map((x, i) => {
      if (i === index) {
        return {
          ...x,
          teamName: e.target.value,
        };
      }
      return x;
    });
    this.setState({
      department: {
        ...this.state.department,
        teams,
      },
    });
  };

  componentDidMount() {
    const { officeId } = this.props;
    // ログインに戻す
    if (!officeId) {
      this.props.history.push('/login');
      return;
    }
    this.handleFetch();
  }

  render() {
    // console.log(this.state);
    // console.log(this.props);
    const { departments, department, visibleNew, visibleEdit } = this.state;

    const isNewDepartmentDuplicated = !!departments.filter(
      x => x.departmentName === department.departmentName
    ).length;

    const isEditDepartmentDuplicated = !!departments
      .filter(x => x.departmentId !== department.departmentId)
      .filter(x => x.departmentName === department.departmentName).length;

    // TODO : API側で選択済みは削除させないなどのハンドリングを追加
    const isTeamDuplicated = !!department.teams
      .map(x => x.teamName)
      .filter((x, i, self) => self.indexOf(x) !== self.lastIndexOf(x)).length;

    return (
      <>
        <Button
          onClick={() => {
            this.setState({ visibleNew: true });
          }}
          type="primary"
          style={{ marginBottom: 16 }}
        >
          追加
        </Button>
        <Table
          // @ts-ignore
          columns={this.columns}
          dataSource={departments}
          rowKey="departmentId"
        />
        <Modal
          title="部門を追加"
          visible={visibleNew}
          onOk={() => this.handlePost()}
          onCancel={() => this.setState({ ...initialState, departments })}
          cancelText="キャンセル"
          okButtonProps={{
            disabled:
              !department.departmentName ||
              isNewDepartmentDuplicated ||
              isTeamDuplicated,
          }}
        >
          <Form>
            <Form.Item
              validateStatus={isNewDepartmentDuplicated ? 'error' : undefined}
              help={
                isNewDepartmentDuplicated
                  ? '既に登録されている名前は使用できません'
                  : undefined
              }
            >
              <Input
                placeholder="部門名"
                value={department.departmentName}
                onChange={this.onChangeDepartmentName}
              />
            </Form.Item>
            {department.teams.length ? (
              <Form.Item
                validateStatus={isTeamDuplicated ? 'error' : undefined}
                help={
                  isTeamDuplicated
                    ? '部門の中で同じチーム名は使用できません'
                    : undefined
                }
              >
                {department.teams.map((team, index) => (
                  <Input
                    key={team.teamId}
                    placeholder={`チーム名 ${index + 1}`}
                    value={team.teamName}
                    onChange={this.onChangeTeamName(index)}
                    addonAfter={
                      <Icon type="delete" onClick={this.deleteTeams(index)} />
                    }
                  />
                ))}
              </Form.Item>
            ) : (
              <div />
            )}
            <Tag
              onClick={this.addTeams}
              style={{ background: '#fff', borderStyle: 'dashed' }}
            >
              <Icon type="plus" /> チームを追加
            </Tag>
          </Form>
        </Modal>

        <Modal
          title="部門を編集"
          visible={visibleEdit}
          onOk={() => this.handlePut()}
          onCancel={() => this.setState({ ...initialState, departments })}
          cancelText="キャンセル"
          okButtonProps={{
            disabled:
              !department.departmentName ||
              isEditDepartmentDuplicated ||
              isTeamDuplicated,
          }}
        >
          <Form>
            <Form.Item
              validateStatus={isEditDepartmentDuplicated ? 'error' : undefined}
              help={
                isEditDepartmentDuplicated
                  ? '既に登録されている名前は使用できません'
                  : undefined
              }
            >
              <Input
                placeholder="部門名"
                value={department.departmentName}
                onChange={this.onChangeDepartmentName}
              />
            </Form.Item>

            {department.teams.length ? (
              <Form.Item
                validateStatus={isTeamDuplicated ? 'error' : undefined}
                help={
                  isTeamDuplicated
                    ? '部門の中で同じチーム名は使用できません'
                    : undefined
                }
              >
                {department.teams.map((team, index) => (
                  <Input
                    key={team.teamId}
                    placeholder={`チーム名 ${index + 1}`}
                    value={team.teamName}
                    onChange={this.onChangeTeamName(index)}
                    addonAfter={
                      <Icon type="delete" onClick={this.deleteTeams(index)} />
                    }
                  />
                ))}
              </Form.Item>
            ) : (
              <div />
            )}
            <Tag
              onClick={this.addTeams}
              style={{ background: '#fff', borderStyle: 'dashed' }}
            >
              <Icon type="plus" /> チームを追加
            </Tag>
          </Form>
        </Modal>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDepartment);
