import * as React from 'react';
import {
  Icon,
  Modal,
  DatePicker,
  Select,
  Form,
  Typography,
  List,
  Button,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { isBefore } from 'date-fns';
import moment from 'moment';

import { DailyReport } from '../types/apiResponse/dailyReports.response';
import { Department, Team } from '../types/apiResponse/departments.response';

import { Work } from '../types/apiResponse/works.response';
import { Product } from '../types/apiResponse/products.response';
import { Client } from '../types/apiResponse/clients.response';

const { Option } = Select;
const { Text } = Typography;

type E = React.ChangeEvent<HTMLTextAreaElement>;

type Props = {
  dailyReports: DailyReport[];
  departments: Department[];
  teams: Team[];

  works: Work[];
  clients: Client[];
  products: Product[];

  dailyReportDate: Date;
  dailyReportCopyStartDate: Date;
  dailyReportCopyEndDate: Date;
  departmentId?: number;
  teamId?: number;
  dailyReport?: DailyReport;
  remarks?: string;

  visibleNew: boolean;
  visibleEdit: boolean;
  isNewDuplicated: boolean;
  isEditDuplicated: boolean;
  isCopyModal: boolean;

  onChangeDate: (date: moment.Moment, dateString: string) => void;
  onChangeCopyStartDate: (date: moment.Moment, dateString: string) => void;
  onChangeCopyEndDate: (date: moment.Moment, dateString: string) => void;
  onChangeDepartments: (value: string) => void;
  onChangeTeams: (value: string) => void;
  onChangeRemarks: (e: E) => void;
  onChangeDailyReport: (dailyReport: DailyReport) => void;
  onCannelAdd: () => void;
  onCannelEdit: () => void;
  handlePost: () => void;
  handlePut: () => void;
};

interface State {
  workId: number;
  clientId?: number;
  productIdForWork?: number;
  productId: number;

  visibleWorkAdd: boolean;
  visibleProductAdd: boolean;
}

const initialState = {
  workId: 0,
  clientId: undefined,
  productIdForWork: undefined,
  productId: 0,

  visibleWorkAdd: false,
  visibleProductAdd: false,
};

class DailyReportAddEditCopyModal extends React.PureComponent<Props, State> {
  state: State = initialState;

  onChangeWorks = (value: string) => {
    const workId = parseInt(value, 10);
    const { clientId } = this.props.works.filter(x => x.workId === workId)[0];
    this.setState({ workId, clientId });
  };

  onChangeClients = (value?: string) => {
    this.setState({
      clientId: value ? parseInt(value, 10) : initialState.clientId,
    });
  };

  onChangeProductsForWork = (value?: string) => {
    this.setState({
      productIdForWork: value
        ? parseInt(value, 10)
        : initialState.productIdForWork,
    });
  };

  onChangeProducts = (value: string) => {
    this.setState({
      productId: value ? parseInt(value, 10) : initialState.productId,
    });
  };

  render() {
    const {
      workId,
      clientId,
      productIdForWork,
      productId,
      visibleWorkAdd,
      visibleProductAdd,
    } = this.state;
    const {
      departments,
      teams,
      remarks,

      works,
      clients,
      products,

      dailyReport,
      dailyReportDate,
      dailyReportCopyStartDate,
      dailyReportCopyEndDate,
      departmentId,
      teamId,

      visibleNew,
      visibleEdit,
      isCopyModal,
      isNewDuplicated,
      isEditDuplicated,

      onChangeDate,
      onChangeCopyStartDate,
      onChangeCopyEndDate,
      onChangeDepartments,
      onChangeTeams,
      onChangeRemarks,
      onChangeDailyReport,
      onCannelAdd,
      onCannelEdit,
      handlePost,
      handlePut,
    } = this.props;

    const isNewDuplicatedNotCopy = !isCopyModal && isNewDuplicated;

    const isNewWorkDuplicated = dailyReport
      ? !!dailyReport.works
          // TODO : レスポンスがnullになるのでとりあえずこれで対応
          .map(x => ({
            ...x,
            dailyReportProductId: x.dailyReportProductId || undefined,
            clientId: x.clientId || undefined,
          }))
          .filter(
            x =>
              x.workId === workId &&
              x.productId === productIdForWork &&
              x.clientId === clientId
          ).length
      : false;

    const isNewProductDuplicated = dailyReport
      ? !!dailyReport.products.filter(x => x.productId === productId).length
      : false;

    return (
      <>
        {/* 日報の登録 */}
        <Modal
          title={`日報の追加 ${isCopyModal ? '(コピー)' : ''}`}
          visible={visibleNew}
          onOk={handlePost}
          onCancel={onCannelAdd}
          cancelText="キャンセル"
          okButtonProps={{ disabled: isNewDuplicatedNotCopy }}
        >
          <Form>
            <Form.Item
              validateStatus={isNewDuplicatedNotCopy ? 'error' : undefined}
              help={
                isNewDuplicatedNotCopy
                  ? '既に登録されている内容は登録できません'
                  : undefined
              }
            >
              {!isCopyModal ? (
                <DatePicker
                  value={moment(dailyReportDate)}
                  onChange={onChangeDate}
                />
              ) : (
                <div>
                  <DatePicker
                    format="YYYY-MM-DD"
                    value={moment(dailyReportCopyStartDate)}
                    onChange={onChangeCopyStartDate}
                    placeholder="開始時刻"
                  />
                  <span> 〜 </span>
                  <DatePicker
                    disabledDate={endValue => {
                      if (endValue) {
                        return isBefore(
                          endValue.toDate(),
                          dailyReportCopyStartDate
                        );
                      }
                      return false;
                    }}
                    format="YYYY-MM-DD"
                    value={moment(dailyReportCopyEndDate)}
                    onChange={onChangeCopyEndDate}
                    placeholder="終了時刻"
                  />
                </div>
              )}
              <Select
                placeholder="部門"
                value={departmentId ? `${departmentId}` : undefined}
                style={{ width: '100%' }}
                onChange={onChangeDepartments}
              >
                {departments
                  .filter(v => !!v.visibleFlag)
                  .map(v => (
                    <Option key={v.departmentId} value={`${v.departmentId}`}>
                      {v.departmentName}
                    </Option>
                  ))
                  .concat([
                    <Option
                      key={0}
                      value={undefined}
                      style={{ color: '#bfbfbf' }}
                    >
                      選択しない
                    </Option>,
                  ])}
              </Select>
              <Select
                placeholder="チーム"
                value={teamId ? `${teamId}` : undefined}
                style={{ width: '100%' }}
                onChange={onChangeTeams}
              >
                {teams
                  .map(v => (
                    <Option key={v.teamId} value={`${v.teamId}`}>
                      {v.teamName}
                    </Option>
                  ))
                  .concat([
                    <Option
                      key={0}
                      value={undefined}
                      style={{ color: '#bfbfbf' }}
                    >
                      選択しない
                    </Option>,
                  ])}
              </Select>
            </Form.Item>
          </Form>
          {/* コピーの場合は、作業と製品のコピー内容も表示 */}
          {isCopyModal && dailyReport && (
            <div>
              <div style={{ marginBottom: 24 }}>
                <Text strong underline>
                  作業
                </Text>
                <List
                  bordered
                  dataSource={dailyReport.works}
                  renderItem={work => (
                    <List.Item
                      actions={[
                        <Icon
                          type="delete"
                          onClick={() => {
                            onChangeDailyReport({
                              ...dailyReport,
                              works: dailyReport.works.filter(
                                x =>
                                  !(
                                    x.workId === work.workId &&
                                    x.clientId === work.clientId &&
                                    x.productId === work.productId
                                  )
                              ),
                            });
                          }}
                        />,
                      ]}
                    >
                      {(() => {
                        let workName = work.clientId
                          ? `${work.workName} (${work.clientName})`
                          : work.workName;
                        // 製品が紐づけられている場合には製品名を検索
                        const filteredProducts = dailyReport.products.filter(
                          x => work.productId && work.productId === x.productId
                        );
                        // 配列の長さチェック
                        const productName = filteredProducts.length
                          ? filteredProducts[0].productName
                          : undefined;
                        workName = productName
                          ? `【${productName}】${workName}`
                          : workName;
                        return workName;
                      })()}
                    </List.Item>
                  )}
                />
                {!visibleWorkAdd && (
                  <a
                    href="#"
                    onClick={() => {
                      this.setState({
                        visibleWorkAdd: true,
                      });
                    }}
                  >
                    作業を追加
                  </a>
                )}
                {visibleWorkAdd && (
                  <Form>
                    <Form.Item
                      validateStatus={isNewWorkDuplicated ? 'error' : undefined}
                      help={
                        isNewWorkDuplicated
                          ? '既に登録されている内容は登録できません'
                          : undefined
                      }
                    >
                      <Select
                        placeholder="作業"
                        value={workId ? `${workId}` : undefined}
                        style={{ width: '100%' }}
                        onChange={this.onChangeWorks}
                      >
                        {works
                          .filter(v => !!v.visibleFlag)
                          .map(v => (
                            <Option key={v.workId} value={`${v.workId}`}>
                              {v.clientId
                                ? `${v.workName} (${v.clientName})`
                                : v.workName}
                            </Option>
                          ))}
                      </Select>
                      <Select
                        placeholder="取引先"
                        value={clientId ? `${clientId}` : undefined}
                        style={{ width: '100%' }}
                        onChange={this.onChangeClients}
                      >
                        {clients
                          .map(v => (
                            <Option key={v.clientId} value={`${v.clientId}`}>
                              {v.clientName}
                            </Option>
                          ))
                          .concat([
                            <Option
                              key={0}
                              value={undefined}
                              style={{ color: '#bfbfbf' }}
                            >
                              選択しない
                            </Option>,
                          ])}
                      </Select>
                      <Select
                        placeholder="製品"
                        value={
                          productIdForWork ? `${productIdForWork}` : undefined
                        }
                        style={{ width: '100%' }}
                        onChange={this.onChangeProductsForWork}
                      >
                        {dailyReport &&
                          dailyReport.products
                            .filter(v => !!v.visibleFlag)
                            .map(v => (
                              <Option
                                key={v.productId}
                                value={`${v.productId}`}
                              >
                                {v.productName}
                              </Option>
                            ))
                            .concat([
                              <Option
                                key={0}
                                value={undefined}
                                style={{ color: '#bfbfbf' }}
                              >
                                選択しない
                              </Option>,
                            ])}
                      </Select>
                      <Button
                        onClick={() => {
                          const selectedWork = works.filter(
                            x => x.workId === workId
                          )[0];
                          const selectedProductForWork = dailyReport.products.filter(
                            x => x.productId === productIdForWork
                          ).length
                            ? dailyReport.products.filter(
                                x => x.productId === productIdForWork
                              )[0]
                            : undefined;
                          onChangeDailyReport({
                            ...dailyReport,
                            works: [
                              ...dailyReport.works,
                              {
                                workId,
                                clientId,
                                productId: selectedProductForWork
                                  ? selectedProductForWork.productId
                                  : undefined,
                                workName: selectedWork.workName,
                                clientName: selectedWork.clientName,
                                productName: selectedProductForWork
                                  ? selectedProductForWork.productName
                                  : undefined,
                                dailyReportWorkId: 0,
                                dailyReportProductId: 0,
                                visibleFlag: 1,
                              },
                            ],
                          });
                          this.setState({
                            ...initialState,
                          });
                        }}
                        type="primary"
                        disabled={!workId || isNewWorkDuplicated}
                        style={{ marginRight: 8 }}
                      >
                        追加
                      </Button>
                      <Button
                        onClick={() => {
                          this.setState({
                            ...initialState,
                          });
                        }}
                      >
                        キャンセル
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </div>
              <div style={{ marginBottom: 24 }}>
                <Text strong underline>
                  製品
                </Text>
                <List
                  bordered
                  dataSource={dailyReport.products}
                  renderItem={product => (
                    <List.Item
                      actions={[
                        <Icon
                          type="delete"
                          onClick={() => {
                            onChangeDailyReport({
                              ...dailyReport,
                              works: dailyReport.works.filter(
                                x => x.productId !== product.productId
                              ),
                              products: dailyReport.products.filter(
                                x => x.productId !== product.productId
                              ),
                            });
                          }}
                        />,
                      ]}
                    >
                      {`${product.productName} (${product.unitPrice}円)`}
                    </List.Item>
                  )}
                />
                {!visibleProductAdd && (
                  <a
                    href="#"
                    onClick={() => {
                      this.setState({
                        visibleProductAdd: true,
                      });
                    }}
                  >
                    製品を追加
                  </a>
                )}
                {visibleProductAdd && (
                  <Form>
                    <Form.Item
                      validateStatus={
                        isNewProductDuplicated ? 'error' : undefined
                      }
                      help={
                        isNewProductDuplicated
                          ? '既に登録されている内容は登録できません'
                          : undefined
                      }
                    >
                      <Select
                        placeholder="製品"
                        value={productId ? `${productId}` : undefined}
                        style={{ width: '100%' }}
                        onChange={this.onChangeProducts}
                      >
                        {products
                          .filter(v => !!v.visibleFlag)
                          .map(v => (
                            <Option key={v.productId} value={`${v.productId}`}>
                              {`${v.productName} (${v.unitPrice}円)`}
                            </Option>
                          ))}
                      </Select>
                      <Button
                        onClick={() => {
                          const selectedProduct = products.filter(
                            x => x.productId === productId
                          )[0];
                          onChangeDailyReport({
                            ...dailyReport,
                            products: [
                              ...dailyReport.products,
                              {
                                productId,
                                dailyReportProductId: 0,
                                productName: selectedProduct.productName,
                                unitPrice: selectedProduct.unitPrice,
                                quantityUnit: selectedProduct.quantityUnit,
                                visibleFlag: 1,
                              },
                            ],
                          });
                          this.setState({
                            ...initialState,
                          });
                        }}
                        type="primary"
                        disabled={!productId || isNewProductDuplicated}
                        style={{ marginRight: 8 }}
                      >
                        追加
                      </Button>
                      <Button
                        onClick={() => {
                          this.setState({
                            ...initialState,
                          });
                        }}
                      >
                        キャンセル
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </div>
            </div>
          )}
          <TextArea
            rows={4}
            placeholder="備考"
            value={remarks}
            onChange={onChangeRemarks}
          />
        </Modal>

        {/* 日報の編集 */}
        <Modal
          title="日報の編集"
          visible={visibleEdit}
          onOk={handlePut}
          onCancel={onCannelEdit}
          cancelText="キャンセル"
          okButtonProps={{ disabled: isEditDuplicated }}
        >
          <Form>
            <Form.Item
              validateStatus={isEditDuplicated ? 'error' : undefined}
              help={
                isEditDuplicated
                  ? '既に登録されている内容は登録できません'
                  : undefined
              }
            >
              <DatePicker
                value={moment(dailyReportDate)}
                onChange={onChangeDate}
              />
              <Select
                placeholder="部門"
                value={departmentId ? `${departmentId}` : undefined}
                style={{ width: '100%' }}
                onChange={onChangeDepartments}
              >
                {departments
                  .filter(v => !!v.visibleFlag)
                  .map(v => (
                    <Option key={v.departmentId} value={`${v.departmentId}`}>
                      {v.departmentName}
                    </Option>
                  ))
                  .concat([
                    <Option
                      key={0}
                      value={undefined}
                      style={{ color: '#bfbfbf' }}
                    >
                      選択しない
                    </Option>,
                  ])}
              </Select>
              <Select
                placeholder="チーム"
                value={teamId ? `${teamId}` : undefined}
                style={{ width: '100%' }}
                onChange={onChangeTeams}
              >
                {teams
                  .map(v => (
                    <Option key={v.teamId} value={`${v.teamId}`}>
                      {v.teamName}
                    </Option>
                  ))
                  .concat([
                    <Option
                      key={0}
                      value={undefined}
                      style={{ color: '#bfbfbf' }}
                    >
                      選択しない
                    </Option>,
                  ])}
              </Select>
            </Form.Item>
            <TextArea
              rows={4}
              placeholder="備考"
              value={remarks}
              onChange={onChangeRemarks}
            />
          </Form>
        </Modal>
      </>
    );
  }
}

export default DailyReportAddEditCopyModal;
