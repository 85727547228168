import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { AxiosError } from 'axios';
import { Button, Table, Divider, Icon, Modal, Form, Switch } from 'antd';

import { CustomInput as Input } from './components/CustomAntd';

import httpUtils from './utils/httpUtils';
import {
  PostKbnsReq,
  PutKbnsReq,
  DeleteKbnsReq,
} from './types/apiRequest/system.request';
import { GetKbnsRes, Kbn } from './types/apiResponse/system.response';
import { ApplicationState } from './reducers';

type E = React.ChangeEvent<HTMLInputElement>;

interface State {
  kbns: Kbn[];
  visibleNew: boolean;
  visibleEdit: boolean;
  kbnId: number;
  kbnCategory: string;
  kbnName: string;
}

const initialState = {
  kbns: [],
  visibleNew: false,
  visibleEdit: false,
  kbnId: 0,
  kbnCategory: '',
  kbnName: '',
};

const mapStateToProps = (state: ApplicationState) => ({
  officeId: state.baseReducer.selectedOfficeId,
});

const mapDispatchToProps = () => ({});

type Props = RouteComponentProps<{}> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class SettingSystemKbn extends React.PureComponent<Props, State> {
  columns = [
    {
      title: '区分名',
      dataIndex: 'kbnName',
      key: 'kbnName',
      sorter: (a: Kbn, b: Kbn) => (a.kbnName < b.kbnName ? -1 : 1),
    },
    {
      title: '区分カテゴリー',
      dataIndex: 'kbnCategoryName',
      key: 'kbnCategoryName',
      sorter: (a: Kbn, b: Kbn) => (a.kbnCategory < b.kbnCategory ? -1 : 1),
    },
    {
      title: '表示 / 非表示',
      render: (record: Kbn) =>
        // officeIdが存在する場合のみ編集可能
        record.officeId && (
          <Switch
            checked={!!record.visibleFlag}
            onChange={() => {
              this.handleSwitch(record);
            }}
          />
        ),
    },
    {
      title: '編集 / 削除',
      dataIndex: '',
      key: 'x',
      // @ts-ignore
      render: (record: Kbn) =>
        // officeIdが存在する場合のみ編集・削除可能
        record.officeId && (
          <span>
            <Icon
              type="edit"
              onClick={() => {
                this.setState({
                  kbnId: record.kbnId,
                  kbnCategory: 'worker',
                  kbnName: record.kbnName,
                  visibleEdit: true,
                });
              }}
            />
            <Divider type="vertical" />
            <Icon
              type="delete"
              onClick={() => {
                const handleDelete = this.handleDelete;
                Modal.confirm({
                  title: 'このデータを削除しますか？',
                  content: '削除すると、元に戻せません。',
                  cancelText: 'キャンセル',
                  onOk() {
                    handleDelete(record.kbnId, record.kbnCategory);
                  },
                  onCancel() {},
                });
              }}
            />
          </span>
        ),
    },
  ];

  state: State = initialState;

  handleFetch = async () => {
    const { officeId } = this.props;
    try {
      const res = await httpUtils.get(`/system/kbns`, {
        params: { officeId },
      });
      const kbns: GetKbnsRes = res.data;
      this.setState({ kbns });
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePost = async () => {
    const { officeId } = this.props;
    const { kbns, kbnName, kbnCategory } = this.state;
    const req: PostKbnsReq = {
      officeId,
      kbnCategory,
      kbnName,
    };
    try {
      await httpUtils.post(`/system/kbns`, req);
      this.setState({ ...initialState, kbns });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePut = async () => {
    const { officeId } = this.props;
    const { kbns, kbnId, kbnCategory, kbnName } = this.state;
    const req: PutKbnsReq = {
      officeId,
      kbnId,
      kbnCategory,
      kbnName,
    };
    try {
      await httpUtils.put(`/system/kbns`, req);
      this.setState({ ...initialState, kbns });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleDelete = async (kbnId: number, kbnCategory: string) => {
    const { officeId } = this.props;
    const req: DeleteKbnsReq = {
      officeId,
      kbnId,
      kbnCategory,
    };
    try {
      await httpUtils.delete(`/system/kbns`, {
        data: req,
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleSwitch = async (record: Kbn) => {
    const { officeId } = this.props;
    try {
      await httpUtils.put(`/system/kbns`, {
        ...record,
        officeId,
        visibleFlag: record.visibleFlag === 1 ? 0 : 1,
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  onChangeKbnName = (e: E) => {
    this.setState({ kbnName: e.target.value });
  };

  async componentDidMount() {
    const { officeId } = this.props;
    // ログインに戻す
    if (!officeId) {
      this.props.history.push('/login');
      return;
    }
    try {
      const res = await httpUtils.get(`/system/kbns`, {
        params: { officeId },
      });
      const kbns: GetKbnsRes = res.data;
      this.setState({ kbns });
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  }

  render() {
    // console.log(this.state);
    // console.log(this.props);
    const {
      kbns,
      kbnId,
      kbnCategory,
      kbnName,
      visibleNew,
      visibleEdit,
    } = this.state;

    const isNewDuplicated = !!kbns
      .filter(x => x.kbnCategory === kbnCategory)
      .filter(x => x.kbnName === kbnName).length;
    const isEditDuplicated = !!kbns
      .filter(x => x.kbnCategory === kbnCategory)
      .filter(x => x.kbnId !== kbnId)
      .filter(x => x.kbnName === kbnName).length;

    return (
      <>
        <Button
          onClick={() => {
            this.setState({ visibleNew: true, kbnCategory: 'worker' });
          }}
          type="primary"
          style={{ marginBottom: 16 }}
        >
          従業員区分を追加
        </Button>
        <Table
          // @ts-ignore
          columns={this.columns}
          dataSource={kbns}
          rowKey={(record: Kbn) => `${record.kbnCategory}-${record.kbnId}`}
        />
        <Modal
          title="従業員区分を追加"
          visible={visibleNew}
          onOk={() => this.handlePost()}
          onCancel={() => this.setState({ ...initialState, kbns })}
          cancelText="キャンセル"
          okButtonProps={{ disabled: !kbnName || isNewDuplicated }}
        >
          <Form>
            <Form.Item
              validateStatus={isNewDuplicated ? 'error' : undefined}
              help={
                isNewDuplicated
                  ? '既に登録されている名前は使用できません'
                  : undefined
              }
            >
              <Input
                placeholder="従業員区分名"
                value={kbnName}
                onChange={this.onChangeKbnName}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="従業員区分を編集"
          visible={visibleEdit}
          onOk={() => this.handlePut()}
          onCancel={() => this.setState({ ...initialState, kbns })}
          cancelText="キャンセル"
          okButtonProps={{ disabled: !kbnName || isEditDuplicated }}
        >
          <Form>
            <Form.Item
              validateStatus={isEditDuplicated ? 'error' : undefined}
              help={
                isEditDuplicated
                  ? '既に登録されている名前は使用できません'
                  : undefined
              }
            >
              <Input
                placeholder="区分名"
                value={kbnName}
                onChange={this.onChangeKbnName}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingSystemKbn);
