import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { AxiosError } from 'axios';
import {
  Button,
  Table,
  Divider,
  Icon,
  Modal,
  Select,
  DatePicker,
  Form,
  Switch,
} from 'antd';
import moment from 'moment';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import ja from 'date-fns/locale/ja';

import { CustomInput as Input } from './components/CustomAntd';

import { PostPutWorkersReq } from './types/apiRequest/workers.request';
import { GetWorkersRes, Worker } from './types/apiResponse/workers.response';
import { GetKbnsRes, Kbn } from './types/apiResponse/system.response';
import httpUtils from './utils/httpUtils';
import { ApplicationState } from './reducers';

const { Option } = Select;

type E = React.ChangeEvent<HTMLInputElement>;

const mapStateToProps = (state: ApplicationState) => ({
  officeId: state.baseReducer.selectedOfficeId,
});

const mapDispatchToProps = () => ({});

type Props = RouteComponentProps<{}> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface State {
  workers: Worker[];
  kbns: Kbn[];
  visibleNew: boolean;
  visibleEdit: boolean;
  workerId: number;
  workerKbnId: number;
  workerName: string;
  workerNameKana?: string;
  workerNameEnglish?: string;
  workerCode: string;
  joinedDate?: Date;
  birthDate?: Date;
  sexKbnId?: number;
  postalCode?: string;
  address1?: string;
  address2?: string;
  payment: number;
}

const initialState = {
  workers: [],
  kbns: [],
  visibleNew: false,
  visibleEdit: false,
  workerId: 0,
  workerKbnId: 0,
  workerName: '',
  workerNameKana: undefined,
  workerNameEnglish: undefined,
  workerCode: '',
  joinedDate: undefined,
  birthDate: undefined,
  sexKbnId: undefined,
  postalCode: undefined,
  address1: undefined,
  address2: undefined,
  payment: 0,
};

class AdminWorker extends React.PureComponent<Props, State> {
  columns = [
    {
      title: '氏名',
      dataIndex: 'workerName',
      key: 'workerName',
      defaultSortOrder: 'ascend',
      sorter: (a: Worker, b: Worker) => (a.workerName < b.workerName ? -1 : 1),
    },
    {
      title: '従業員コード',
      dataIndex: 'workerCode',
      key: 'workerCode',
      sorter: (a: Worker, b: Worker) => (a.workerCode < b.workerCode ? -1 : 1),
    },
    {
      title: '従業員区分',
      dataIndex: 'workerKbnName',
      key: 'workerKbnName',
      sorter: (a: Worker, b: Worker) =>
        a.workerKbnName < b.workerKbnName ? -1 : 1,
    },
    {
      title: '入社年月日',
      dataIndex: 'joinedDate',
      key: 'joinedDate',
      sorter: (a: Worker, b: Worker) =>
        (a.joinedDate || '') < (b.joinedDate || '') ? -1 : 1,
      render: (joinedDate?: Date) =>
        joinedDate
          ? format(joinedDate, 'YYYY/MM/DD(dd)', {
              locale: ja,
            })
          : '',
    },
    {
      title: '時給(円)',
      dataIndex: 'payment',
      key: 'payment',
      sorter: (a: Worker, b: Worker) => (a.payment < b.payment ? -1 : 1),
    },
    {
      title: '表示 / 非表示',
      render: (record: Worker) => (
        <Switch
          checked={!!record.visibleFlag}
          onChange={() => {
            this.handleSwitch(record);
          }}
        />
      ),
    },
    {
      title: '編集 / 削除',
      dataIndex: '',
      key: 'x',
      // @ts-ignore
      render: (record: Worker) => (
        <span>
          <Icon
            type="edit"
            onClick={() => {
              this.setState({
                ...this.state,
                ...record,
                visibleEdit: true,
              });
            }}
          />
          <Divider type="vertical" />
          <Icon
            type="delete"
            onClick={() => {
              const handleDelete = this.handleDelete;
              Modal.confirm({
                title: 'このデータを削除しますか？',
                content: '削除すると、元に戻せません。',
                cancelText: 'キャンセル',
                onOk() {
                  handleDelete(record.workerId);
                },
                onCancel() {},
              });
            }}
          />
        </span>
      ),
    },
  ];

  state: State = initialState;

  handleFetch = async () => {
    const { officeId } = this.props;
    try {
      const [res1, res2] = await Promise.all([
        httpUtils.get(`/workers`, {
          params: { officeId },
        }),
        httpUtils.get(`/system/kbns`, {
          params: { officeId },
        }),
      ]);
      const workers: GetWorkersRes = res1.data;
      const kbns: GetKbnsRes = res2.data;
      this.setState({ workers, kbns });
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePost = async () => {
    const { officeId } = this.props;
    const req: PostPutWorkersReq = {
      ...this.state,
      officeId,
    };
    try {
      await httpUtils.post(`/workers`, req);
      this.setState({ ...initialState });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePut = async () => {
    const { officeId } = this.props;
    const { workerId } = this.state;
    const req: PostPutWorkersReq = {
      ...this.state,
      officeId,
    };
    try {
      await httpUtils.put(`/workers/${workerId}`, req);
      this.setState({ ...initialState });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleDelete = async (id: number) => {
    const { officeId } = this.props;
    try {
      await httpUtils.delete(`/workers/${id}`, {
        data: {
          officeId,
        },
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleSwitch = async (record: Worker) => {
    const { officeId } = this.props;
    const { workerId } = record;
    try {
      await httpUtils.put(`/workers/${workerId}`, {
        ...record,
        officeId,
        visibleFlag: record.visibleFlag === 1 ? 0 : 1,
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  onChangeWorkerKbn = (value: string) => {
    const workerKbnId = parseInt(value, 10);
    this.setState({ workerKbnId });
  };

  onChangeWorkerName = (e: E) => {
    this.setState({ workerName: e.target.value });
  };

  onChangeWorkerNameKana = (e: E) => {
    this.setState({
      workerNameKana: e.target.value || initialState.workerNameKana,
    });
  };

  onChangeWorkerNameEnglish = (e: E) => {
    this.setState({
      workerNameEnglish: e.target.value || initialState.workerNameEnglish,
    });
  };

  onChangeWorkerCode = (e: E) => {
    this.setState({ workerCode: e.target.value });
  };

  onChangeJoinedDate = (_: any, dateString: string) => {
    this.setState({ joinedDate: dateString ? parse(dateString) : undefined });
  };

  onChangeBirthDate = (_: any, dateString: string) => {
    this.setState({ birthDate: dateString ? parse(dateString) : undefined });
  };

  onChangeSexKbn = (value: string) => {
    const sexKbnId = parseInt(value, 10);
    this.setState({ sexKbnId });
  };

  onChangePostalCode = (e: E) => {
    this.setState({ postalCode: e.target.value || initialState.postalCode });
  };

  onChangeAddress1 = (e: E) => {
    this.setState({ address1: e.target.value || initialState.address1 });
  };

  onChangeAddress2 = (e: E) => {
    this.setState({ address2: e.target.value || initialState.address2 });
  };

  onChangePayment = (e: E) => {
    const payment = parseFloat(e.target.value);
    if (payment && !Number.isNaN(payment)) {
      this.setState({ payment });
    }
  };

  componentDidMount() {
    const { officeId } = this.props;
    // ログインに戻す
    if (!officeId) {
      this.props.history.push('/login');
      return;
    }
    this.handleFetch();
  }

  render() {
    const {
      workers,
      kbns,
      visibleNew,
      visibleEdit,
      workerKbnId,
      workerId,
      workerName,
      workerNameKana,
      workerNameEnglish,
      workerCode,
      joinedDate,
      birthDate,
      sexKbnId,
      postalCode,
      address1,
      address2,
      payment,
    } = this.state;

    const isNewDuplicated = !!workers.filter(x => x.workerCode === workerCode)
      .length;

    const isEditDuplicated = !!workers
      .filter(x => x.workerId !== workerId)
      .filter(x => x.workerCode === workerCode).length;

    return (
      <>
        <Button
          onClick={() => {
            this.setState({ visibleNew: true });
          }}
          type="primary"
          style={{ marginBottom: 16 }}
        >
          追加
        </Button>
        <Table
          // @ts-ignore
          columns={this.columns}
          dataSource={workers}
          rowKey="workerId"
        />

        {/* 従業員 登録 */}
        <Modal
          title="従業員を追加"
          visible={visibleNew}
          onOk={() => this.handlePost()}
          cancelText="キャンセル"
          onCancel={() => this.setState({ ...initialState, workers, kbns })}
          okButtonProps={{
            disabled:
              !workerKbnId ||
              !workerName ||
              !workerCode ||
              !payment ||
              isNewDuplicated,
          }}
        >
          <Form>
            <Form.Item
              validateStatus={isNewDuplicated ? 'error' : undefined}
              help={
                isNewDuplicated
                  ? '既に登録されている従業員コードは登録できません'
                  : undefined
              }
            >
              <Select
                placeholder="従業員区分"
                value={workerKbnId ? `${workerKbnId}` : undefined}
                style={{ width: '100%' }}
                onChange={this.onChangeWorkerKbn}
              >
                {kbns
                  .filter(v => v.kbnCategory === 'worker')
                  .filter(v => !!v.visibleFlag)
                  .map(v => (
                    <Option key={v.kbnId} value={`${v.kbnId}`}>
                      {v.kbnName}
                    </Option>
                  ))}
              </Select>
              <Input
                placeholder="氏名"
                value={workerName}
                onChange={this.onChangeWorkerName}
              />
              <Input
                placeholder="従業員コード"
                value={workerCode}
                onChange={this.onChangeWorkerCode}
              />
              <Input
                placeholder="時給(円)"
                value={payment || undefined}
                onChange={this.onChangePayment}
              />
            </Form.Item>
            <div>任意項目</div>
            <Form.Item>
              <Input
                placeholder="氏名(カナ)"
                value={workerNameKana}
                onChange={this.onChangeWorkerNameKana}
              />
              <Input
                placeholder="氏名(English)"
                value={workerNameEnglish}
                onChange={this.onChangeWorkerNameEnglish}
              />
              <DatePicker
                style={{ marginRight: 10 }}
                value={joinedDate ? moment(joinedDate) : undefined}
                onChange={this.onChangeJoinedDate}
                placeholder="入社年月日"
              />
              <DatePicker
                value={birthDate ? moment(birthDate) : undefined}
                onChange={this.onChangeBirthDate}
                placeholder="生年月日"
              />
              <Select
                placeholder="性別"
                value={sexKbnId ? `${sexKbnId}` : undefined}
                style={{ width: '100%' }}
                onChange={this.onChangeSexKbn}
              >
                {kbns
                  .filter(v => v.kbnCategory === 'sex')
                  .map(v => (
                    <Option key={v.kbnId} value={`${v.kbnId}`}>
                      {v.kbnName}
                    </Option>
                  ))}
              </Select>
              <Input
                placeholder="郵便番号"
                value={postalCode}
                onChange={this.onChangePostalCode}
              />
              <Input
                placeholder="住所1"
                value={address1}
                onChange={this.onChangeAddress1}
              />
              <Input
                placeholder="住所2"
                value={address2}
                onChange={this.onChangeAddress2}
              />
            </Form.Item>
          </Form>
        </Modal>

        {/* 従業員 編集 */}
        <Modal
          title="従業員を編集"
          visible={visibleEdit}
          onOk={() => this.handlePut()}
          onCancel={() => this.setState({ ...initialState, workers, kbns })}
          cancelText="キャンセル"
          okButtonProps={{
            disabled:
              !workerKbnId ||
              !workerName ||
              !workerCode ||
              !payment ||
              isEditDuplicated,
          }}
        >
          <Form>
            <Form.Item
              validateStatus={isEditDuplicated ? 'error' : undefined}
              help={
                isEditDuplicated
                  ? '既に登録されている従業員コードは登録できません'
                  : undefined
              }
            >
              <Select
                placeholder="従業員区分"
                value={workerKbnId ? `${workerKbnId}` : undefined}
                style={{ width: '100%' }}
                onChange={this.onChangeWorkerKbn}
              >
                {kbns
                  .filter(v => v.kbnCategory === 'worker')
                  .filter(v => !!v.visibleFlag)
                  .map(v => (
                    <Option key={v.kbnId} value={`${v.kbnId}`}>
                      {v.kbnName}
                    </Option>
                  ))}
              </Select>
              <Input
                placeholder="従業員コード"
                value={workerCode}
                onChange={this.onChangeWorkerCode}
              />
              <Input
                placeholder="氏名"
                value={workerName}
                onChange={this.onChangeWorkerName}
              />
              <Input
                placeholder="時給(円)"
                value={payment}
                onChange={this.onChangePayment}
              />
            </Form.Item>
            <div>任意項目</div>
            <Form.Item>
              <Input
                placeholder="氏名(カナ)"
                value={workerNameKana}
                onChange={this.onChangeWorkerNameKana}
              />
              <Input
                placeholder="氏名(English)"
                value={workerNameEnglish}
                onChange={this.onChangeWorkerNameEnglish}
              />
              <DatePicker
                style={{ marginRight: 10 }}
                value={joinedDate ? moment(joinedDate) : undefined}
                onChange={this.onChangeJoinedDate}
                placeholder="入社年月日"
              />
              <DatePicker
                value={birthDate ? moment(birthDate) : undefined}
                onChange={this.onChangeBirthDate}
                placeholder="生年月日"
              />
              <Select
                placeholder="性別"
                value={sexKbnId ? `${sexKbnId}` : undefined}
                style={{ width: '100%' }}
                onChange={this.onChangeSexKbn}
              >
                {kbns
                  .filter(v => v.kbnCategory === 'sex')
                  .map(v => (
                    <Option key={v.kbnId} value={`${v.kbnId}`}>
                      {v.kbnName}
                    </Option>
                  ))}
              </Select>
              <Input
                placeholder="郵便番号"
                value={postalCode}
                onChange={this.onChangePostalCode}
              />
              <Input
                placeholder="住所1"
                value={address1}
                onChange={this.onChangeAddress1}
              />
              <Input
                placeholder="住所2"
                value={address2}
                onChange={this.onChangeAddress2}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminWorker);
