import { call, takeEvery, delay } from 'redux-saga/effects';

// import {} from '~/actions/baseAction';
// import { ApplicationState } from '~/reducers';

function* hoge() {
  yield call(delay, 3000);
}

export default function* saga() {
  yield takeEvery('hoge', hoge);
}
