import {
  CognitoUserPool,
  // CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

const appConfig = {
  region: 'ap-northeast-1',
  IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID || '',
  UserPoolId: process.env.REACT_APP_AWS_USERPOOL_ID || '',
  ClientId: process.env.REACT_APP_AWS_CLIENT_ID || '',
};

const getCognitoUserPool = () => {
  return new CognitoUserPool({
    UserPoolId: appConfig.UserPoolId,
    ClientId: appConfig.ClientId,
  });
};

export default class AwsUtils {
  static getUserToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      // TODO : ここで良いのか確認
      const userPool = getCognitoUserPool();
      const cognitoUser = userPool.getCurrentUser();
      // @ts-ignore
      cognitoUser &&
        // @ts-ignore
        cognitoUser.getSession((err, result) => {
          if (err) {
            // console.log(err)
            reject(err);
          }
          if (result) {
            // console.log('You are now logged in.');
            // console.log("Id      Token + " + result.getIdToken().getJwtToken());
            // console.log("Access  Token + " + result.getAccessToken().getJwtToken());
            // console.log("Refresh Token + " + result.getRefreshToken().getToken());
            resolve(result.getIdToken().getJwtToken());
          }
          reject('No result');
        });
    });
  }

  static signIn(userName: string, password: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const userPool = getCognitoUserPool();
      const authenticationData = {
        Username: userName,
        Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );
      const userData = {
        Username: userName,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          // console.log(cognitoUser);
          cognitoUser.getUserAttributes((err, result) => {
            if (result) {
              result.forEach(v => {
                // const data = v.getValue()
                // console.log(`${v.getName()}:${v.getValue()}`);
              });
              resolve();
            }
            // console.log(err)
            reject(err);
          });
        },
        onFailure: err => {
          // console.log(err)
          reject(err);
        },
      });
    });
  }
}
