import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Layout, Menu, Dropdown, Row, Col, Button } from 'antd';
import { ClickParam } from 'antd/lib/menu';

import {
  GetUsersMeRes,
  User,
  Office,
} from './types/apiResponse/users.response';
import { AxiosError } from 'axios';
import httpUtils from './utils/httpUtils';
import * as baseAction from './actions/baseAction';
import { ApplicationState } from './reducers';
import logo from './assets/kaminashi_header.png';

const { Header } = Layout;

const mapStateToProps = (state: ApplicationState) => ({
  user: state.baseReducer.user,
  selectedOfficeId: state.baseReducer.selectedOfficeId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setUser: (user: User) => dispatch(baseAction.setUser(user)),
  selectOfficeId: (officeId: number) =>
    dispatch(baseAction.selectOfficeId(officeId)),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class AppHeader extends React.PureComponent<Props, {}> {
  selectOffice = (value: ClickParam) => {
    const officeId = parseInt(value.key, 10);
    this.props.selectOfficeId(officeId);
  };

  async componentDidMount() {
    // ヘッダーは必ず読み込まれるので、ユーザー情報をここで取得
    try {
      const res = await httpUtils.get('/users/me');
      const user: GetUsersMeRes = res.data;
      this.props.setUser(user);
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    // 他の事業所に切り替わったらリロード
    const { selectedOfficeId } = this.props;
    if (
      selectedOfficeId &&
      prevProps.selectedOfficeId &&
      selectedOfficeId !== prevProps.selectedOfficeId
    ) {
      window.location.reload();
    }
  }

  render() {
    // console.log(props);
    const { user, selectedOfficeId } = this.props;
    const offices: Office[] = [];
    if (user) {
      user.companies.forEach(company => {
        company.offices.forEach(office => {
          offices.push(office);
        });
      });
    }
    const selectedOffice = offices.filter(x => x.officeId === selectedOfficeId);
    let displayOfficeName = '';
    if (selectedOffice[0]) {
      displayOfficeName = selectedOffice[0].officeName;
    }
    const menu = (
      <Menu>
        {offices
          .filter(x => x.officeId !== selectedOfficeId)
          .map(v => (
            <Menu.Item key={v.officeId} onClick={this.selectOffice}>
              {v.officeName}
            </Menu.Item>
          ))}
      </Menu>
    );

    return (
      <Header
        className="header"
        style={{ backgroundColor: '#01b0f0', paddingLeft: 0 }}
      >
        <Row>
          <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="Logo" style={{ height: 64 }} />
            <span
              style={{ color: 'white', fontSize: '1.8rem', fontWeight: 'bold' }}
            >
              工数管理
            </span>
          </Col>
          <Col
            span={12}
            style={{
              textAlign: 'right',
            }}
          >
            {user && (
              <Dropdown overlay={menu} placement="bottomCenter">
                <Button>{displayOfficeName}</Button>
              </Dropdown>
            )}
          </Col>
        </Row>
      </Header>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppHeader);
