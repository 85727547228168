import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { AxiosError } from 'axios';
import { Button, Table, Divider, Icon, Modal, Menu, Dropdown } from 'antd';
import {
  format,
  parse,
  isAfter,
  isBefore,
  startOfToday,
  eachDay,
  addWeeks,
} from 'date-fns';
import ja from 'date-fns/locale/ja';

import TextField from '@material-ui/core/TextField';
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles';

import DailyReportAddEditCopyModal from './components/DailyReportAddEditCopyModal';
import DailyReportAddEditWorkModal from './components/DailyReportAddEditWorkModal';
import DailyReportAddEditProductModal from './components/DailyReportAddEditProductModal';

import {
  PostDailyReportsReq,
  PutDailyReportsReq,
  PostPutDailyReportsWorksReq,
  PostPutDailyReportsProductsReq,
} from './types/apiRequest/dailyReports.request';
import {
  GetDailyReportsRes,
  DailyReport,
  DailyReportWork,
  DailyReportProduct,
} from './types/apiResponse/dailyReports.response';
import {
  GetDepartmentsRes,
  Department,
  Team,
} from './types/apiResponse/departments.response';
import { GetWorksRes, Work } from './types/apiResponse/works.response';
import { GetProductsRes, Product } from './types/apiResponse/products.response';
import { GetClientsRes, Client } from './types/apiResponse/clients.response';

import httpUtils from './utils/httpUtils';
import { ApplicationState } from './reducers';

type E = React.ChangeEvent<HTMLInputElement>;
type E1 = React.ChangeEvent<HTMLTextAreaElement>;

const styles = (theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: 10,
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
    },
  });

const mapStateToProps = (state: ApplicationState) => ({
  officeId: state.baseReducer.selectedOfficeId,
});

const mapDispatchToProps = () => ({});

type Props = RouteComponentProps<{}> &
  WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface State {
  from: string;
  to: string;

  dailyReportDate: Date;
  dailyReportCopyStartDate: Date;
  dailyReportCopyEndDate: Date;
  remarks?: string;
  departmentId?: number;
  teamId?: number;

  dailyReports: DailyReport[];
  departments: Department[];
  works: Work[];
  products: Product[];
  clients: Client[];

  visibleNew: boolean;
  visibleNewWorks: boolean;
  visibleNewProducts: boolean;

  visibleEdit: boolean;
  visibleEditWorks: boolean;
  visibleEditProducts: boolean;

  dailyReportId: number;
  dailyReport?: DailyReport;

  workId: number;
  clientId?: number;
  dailyReportProductId?: number;
  dailyReportWorkId: number;

  productId: number;
  quantity?: number;

  isCopyModal: boolean;
}

const initialState = {
  from: format(addWeeks(new Date(), -1), 'YYYY-MM-DD'),
  to: format(addWeeks(new Date(), 4), 'YYYY-MM-DD'),

  dailyReportDate: startOfToday(),
  dailyReportCopyStartDate: startOfToday(),
  dailyReportCopyEndDate: startOfToday(),
  remarks: undefined,
  departmentId: undefined,
  teamId: undefined,

  dailyReportId: 0,
  dailyReports: [],
  departments: [],
  works: [],
  products: [],
  clients: [],

  visibleNew: false,
  visibleNewWorks: false,
  visibleNewProducts: false,

  visibleEdit: false,
  visibleEditWorks: false,
  visibleEditProducts: false,

  dailyReport: undefined,

  workId: 0,
  clientId: undefined,
  dailyReportProductId: undefined,
  dailyReportWorkId: 0,

  productId: 0,
  quantity: undefined,

  isCopyModal: false,
};

class AdminDailyReport extends React.PureComponent<Props, State> {
  columns = [
    {
      title: '日付',
      dataIndex: 'dailyReportDate',
      key: 'dailyReportDate',
      defaultSortOrder: 'ascend',
      sorter: (a: DailyReport, b: DailyReport) =>
        a.dailyReportDate <= b.dailyReportDate ? -1 : 1,
      render: (dailyReportDate: string) =>
        format(parse(dailyReportDate), 'YYYY/MM/DD(dd)', {
          locale: ja,
        }),
    },
    {
      title: '部門 / チーム',
      dataIndex: 'department',
      key: 'department',
      // @ts-ignore
      render: (department?: Department, record: DailyReport) => {
        let departmentName = department ? department.departmentName : '';
        departmentName = record.team
          ? `${departmentName} / ${record.team.teamName}`
          : departmentName;
        if (departmentName) {
          return <span key={departmentName}>{departmentName}</span>;
        }
      },
    },
    {
      title: '作業',
      dataIndex: 'works',
      key: 'works',
      render: (works: DailyReportWork[], record: DailyReport) => (
        <div>
          {works
            .map(x => {
              let workName = x.clientId
                ? `${x.workName} (${x.clientName})`
                : x.workName;
              // 製品が紐づけられている場合には製品名を検索
              const filteredProducts = record.products.filter(
                y =>
                  x.dailyReportProductId &&
                  x.dailyReportProductId === y.dailyReportProductId
              );
              // 配列の長さチェック
              const productName = filteredProducts.length
                ? filteredProducts[0].productName
                : undefined;
              workName = productName
                ? `【${productName}】${workName}`
                : workName;
              return { ...x, workName };
            })
            .sort((a, b) => (a.workName > b.workName ? 1 : -1))
            .map(x => (
              <div key={x.dailyReportWorkId}>
                <span>{`${x.workName}　`}</span>
                <Icon
                  type="edit"
                  onClick={() => {
                    this.setState({
                      dailyReport: record,
                      dailyReportWorkId: x.dailyReportWorkId,
                      workId: x.workId,
                      clientId: x.clientId,
                      dailyReportProductId: x.dailyReportProductId,
                      visibleEditWorks: true,
                    });
                  }}
                />
                <Divider type="vertical" />
                <Icon
                  type="delete"
                  onClick={() => {
                    this.handleDeleteWorks(
                      record.dailyReportId,
                      x.dailyReportWorkId
                    );
                  }}
                />
              </div>
            ))}
          <div>
            <a
              href="#"
              onClick={() => {
                this.setState({
                  dailyReport: record,
                  visibleNewWorks: true,
                });
              }}
            >
              作業を追加
            </a>
          </div>
        </div>
      ),
    },
    {
      title: '製品',
      dataIndex: 'products',
      key: 'products',
      render: (products: DailyReportProduct[], record: DailyReport) => (
        <div>
          {products
            .map(x => ({
              ...x,
              productName: x.quantity
                ? `${x.productName} (${x.unitPrice}円) : ${x.quantity}${x.quantityUnit}　`
                : `${x.productName} (${x.unitPrice}円)　`,
            }))
            .sort((a, b) => (a.productName > b.productName ? 1 : -1))
            .map(x => (
              <div key={x.productId}>
                <span>{x.productName}</span>
                <Icon
                  type="edit"
                  onClick={() => {
                    this.setState({
                      dailyReport: record,
                      dailyReportProductId: x.dailyReportProductId,
                      productId: x.productId,
                      quantity: x.quantity,
                      visibleEditProducts: true,
                    });
                  }}
                />
                <Divider type="vertical" />
                <Icon
                  type="delete"
                  onClick={() => {
                    this.handleDeleteProducts(
                      record.dailyReportId,
                      x.dailyReportProductId
                    );
                  }}
                />
              </div>
            ))}
          <div>
            <a
              href="#"
              onClick={() => {
                this.setState({
                  dailyReport: record,
                  visibleNewProducts: true,
                });
              }}
            >
              製品を追加
            </a>
          </div>
        </div>
      ),
    },
    {
      dataIndex: '',
      key: 'x',
      // @ts-ignore
      render: (record: DailyReport) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => {
                  this.setState({
                    dailyReportId: record.dailyReportId,
                    dailyReport: record,
                    dailyReportDate: record.dailyReportDate,
                    departmentId: record.department
                      ? record.department.departmentId
                      : undefined,
                    teamId: record.team ? record.team.teamId : undefined,
                    remarks: record.remarks,
                    visibleEdit: true,
                  });
                }}
              >
                編集
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  const handleDelete = this.handleDelete;
                  Modal.confirm({
                    title: 'このデータを削除しますか？',
                    content: '削除すると、元に戻せません。',
                    cancelText: 'キャンセル',
                    onOk() {
                      handleDelete(record.dailyReportId);
                    },
                    onCancel() {},
                  });
                }}
              >
                削除
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  this.setState({
                    dailyReport: record,
                    departmentId: record.department
                      ? record.department.departmentId
                      : undefined,
                    teamId: record.team ? record.team.teamId : undefined,
                    remarks: record.remarks,
                    visibleNew: true,
                    isCopyModal: true,
                  });
                }}
              >
                コピー
              </Menu.Item>
            </Menu>
          }
        >
          <Icon type="menu" />
        </Dropdown>
      ),
    },
  ];

  state: State = initialState;

  handleFetch = async () => {
    const { officeId } = this.props;
    const { from, to } = this.state;
    try {
      const [res1, res2, res3, res4, res5] = await Promise.all([
        httpUtils.get(`/dailyReports`, {
          params: { officeId, from, to },
        }),
        httpUtils.get(`/departments`, {
          params: { officeId },
        }),
        httpUtils.get(`/works`, {
          params: { officeId },
        }),
        httpUtils.get(`/products`, {
          params: { officeId },
        }),
        httpUtils.get(`/clients`, {
          params: { officeId },
        }),
      ]);
      const dailyReports: GetDailyReportsRes = res1.data;
      const departments: GetDepartmentsRes = res2.data;
      const works: GetWorksRes = res3.data;
      const products: GetProductsRes = res4.data;
      const clients: GetClientsRes = res5.data;
      const fixedDailyReports = dailyReports.map(x => ({
        ...x,
        works: x.works.sort((a, b) => (a.workName > b.workName ? 1 : -1)),
      }));

      this.setState({
        dailyReports: fixedDailyReports,
        departments,
        works,
        products,
        clients,
      });
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePost = async () => {
    const { officeId } = this.props;
    const {
      dailyReports,
      dailyReportDate,
      dailyReportCopyStartDate,
      dailyReportCopyEndDate,
      remarks,
      departmentId,
      teamId,
      dailyReport,
      isCopyModal,
    } = this.state;

    let dates: Date[];
    if (!isCopyModal) {
      dates = [dailyReportDate];
    } else {
      dates = eachDay(dailyReportCopyStartDate, dailyReportCopyEndDate);
    }

    const baseReq: PostDailyReportsReq = {
      remarks,
      departmentId,
      teamId,
      officeId,
      dailyReportDate: dates[0],
      works: dailyReport
        ? dailyReport.works.map(x => ({
            workId: x.workId,
            clientId: x.clientId,
            productId: x.productId,
          }))
        : [],
      products: dailyReport
        ? dailyReport.products.map(x => ({
            productId: x.productId,
          }))
        : [],
    };

    try {
      await Promise.all(
        dates
          // 既に日報がある場合は重複を除く
          .filter(
            date =>
              !dailyReports
                // TODO : レスポンスがnullになるのでとりあえずこれで対応
                .map(x => ({
                  ...x,
                  department: x.department || undefined,
                  team: x.team || undefined,
                }))
                .filter(
                  x =>
                    format(x.dailyReportDate, 'YYYY-MM-DD') ===
                    format(date, 'YYYY-MM-DD')
                )
                .filter(x =>
                  x.department
                    ? x.department.departmentId === departmentId
                    : !departmentId
                )
                .filter(x => (x.team ? x.team.teamId === teamId : !teamId))
                .length
          )
          .map(date => {
            const req = {
              ...baseReq,
              dailyReportDate: date,
            };
            return httpUtils.post(`/dailyReports`, req);
          })
      );
      this.setState({ ...initialState });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePostWorks = async () => {
    const { officeId } = this.props;
    const { dailyReport, workId, clientId, dailyReportProductId } = this.state;
    const dailyReportId = dailyReport && dailyReport.dailyReportId;
    const req: PostPutDailyReportsWorksReq = {
      workId,
      clientId,
      dailyReportProductId,
      officeId,
    };
    try {
      await httpUtils.post(`/dailyReports/${dailyReportId}/works`, req);
      this.setState({ ...initialState });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePostProducts = async () => {
    const { officeId } = this.props;
    const { dailyReport, productId, quantity } = this.state;
    const dailyReportId = dailyReport && dailyReport.dailyReportId;
    const req: PostPutDailyReportsProductsReq = {
      productId,
      quantity,
      officeId,
    };
    try {
      await httpUtils.post(`/dailyReports/${dailyReportId}/products`, req);
      this.setState({ ...initialState });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePut = async () => {
    const { officeId } = this.props;
    const {
      dailyReport,
      dailyReportDate,
      remarks,
      departmentId,
      teamId,
    } = this.state;
    const dailyReportId = dailyReport && dailyReport.dailyReportId;
    const req: PutDailyReportsReq = {
      dailyReportDate,
      remarks,
      departmentId,
      teamId,
      officeId,
    };
    try {
      await httpUtils.put(`/dailyReports/${dailyReportId}`, req);
      this.setState({ ...initialState });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePutWorks = async () => {
    const { officeId } = this.props;
    const {
      dailyReport,
      dailyReportWorkId,
      workId,
      clientId,
      dailyReportProductId,
    } = this.state;
    const dailyReportId = dailyReport && dailyReport.dailyReportId;
    const req: PostPutDailyReportsWorksReq = {
      workId,
      clientId,
      dailyReportProductId,
      officeId,
    };
    try {
      await httpUtils.put(
        `/dailyReports/${dailyReportId}/works/${dailyReportWorkId}`,
        req
      );
      this.setState({ ...initialState });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePutProducts = async () => {
    const { officeId } = this.props;
    const {
      dailyReport,
      dailyReportProductId,
      productId,
      quantity,
    } = this.state;
    const dailyReportId = dailyReport && dailyReport.dailyReportId;
    const req: PostPutDailyReportsProductsReq = {
      productId,
      quantity,
      officeId,
    };
    try {
      await httpUtils.put(
        `/dailyReports/${dailyReportId}/products/${dailyReportProductId}`,
        req
      );
      this.setState({ ...initialState });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleDelete = async (id: number) => {
    const { officeId } = this.props;
    try {
      await httpUtils.delete(`/dailyReports/${id}`, {
        data: {
          officeId,
        },
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleDeleteWorks = async (
    dailyReportId: number,
    dailyReportWorkId: number
  ) => {
    const { officeId } = this.props;
    try {
      await httpUtils.delete(
        `/dailyReports/${dailyReportId}/works/${dailyReportWorkId}`,
        {
          data: {
            officeId,
          },
        }
      );
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleDeleteProducts = async (
    dailyReportId: number,
    dailyReportProductId: number
  ) => {
    const { officeId } = this.props;
    try {
      await httpUtils.delete(
        `/dailyReports/${dailyReportId}/products/${dailyReportProductId}`,
        {
          data: {
            officeId,
          },
        }
      );
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  onChangeDate = (_: any, dateString: string) => {
    if (dateString) {
      this.setState({ dailyReportDate: parse(dateString) });
    }
  };

  onChangeFromToDate = (fromTo: string) => (e: E) => {
    const date = e.target.value;
    if (fromTo === 'from') {
      if (isAfter(date, this.state.to)) {
        this.setState({ from: date, to: date });
        return;
      }
      this.setState({ from: date });
    }
    if (fromTo === 'to') {
      if (isBefore(date, this.state.from)) {
        this.setState({ from: date, to: date });
        return;
      }
      this.setState({ to: date });
    }
  };

  onChangeCopyStartDate = (_: any, dateString: string) => {
    if (dateString) {
      const date = parse(dateString);
      if (isAfter(date, this.state.dailyReportCopyEndDate)) {
        this.setState({
          dailyReportCopyStartDate: date,
          dailyReportCopyEndDate: date,
        });
        return;
      }
      this.setState({ dailyReportCopyStartDate: date });
    }
  };

  onChangeCopyEndDate = (_: any, dateString: string) => {
    if (dateString) {
      const date = parse(dateString);
      if (isBefore(date, this.state.dailyReportCopyStartDate)) {
        this.setState({
          dailyReportCopyStartDate: date,
          dailyReportCopyEndDate: date,
        });
        return;
      }
      this.setState({ dailyReportCopyEndDate: date });
    }
  };

  onChangeRemarks = (e: E1) => {
    this.setState({ remarks: e.target.value });
  };

  onChangeDepartments = (value?: string) => {
    this.setState({
      departmentId: value ? parseInt(value, 10) : initialState.departmentId,
      teamId: initialState.teamId,
    });
  };

  onChangeTeams = (value?: string) => {
    this.setState({
      teamId: value ? parseInt(value, 10) : initialState.teamId,
    });
  };

  onChangeWorks = (value: string) => {
    const workId = parseInt(value, 10);
    const { clientId } = this.state.works.filter(x => x.workId === workId)[0];
    this.setState({ workId, clientId: clientId || initialState.clientId });
  };

  onChangeClients = (value?: string) => {
    this.setState({
      clientId: value ? parseInt(value, 10) : initialState.clientId,
    });
  };

  onChangeDailyReportProducts = (value?: string) => {
    this.setState({
      dailyReportProductId: value
        ? parseInt(value, 10)
        : initialState.dailyReportProductId,
    });
  };

  onChangeProducts = (value: string) => {
    this.setState({
      productId: value ? parseInt(value, 10) : initialState.productId,
    });
  };

  onChangeQuantity = (e: E) => {
    const quantity = parseInt(e.target.value, 10);
    if (quantity) {
      this.setState({ quantity });
      return;
    }
    this.setState({ quantity: undefined });
  };

  onChangeDailyReport = (dailyReport: DailyReport) => {
    this.setState({
      dailyReport,
    });
  };

  componentDidMount() {
    const { officeId } = this.props;
    // ログインに戻す
    if (!officeId) {
      this.props.history.push('/login');
      return;
    }
    this.handleFetch();
  }

  render() {
    const { classes } = this.props;

    const {
      from,
      to,

      dailyReports,
      departments,
      remarks,

      dailyReportId,
      dailyReport,
      dailyReportDate,
      dailyReportCopyStartDate,
      dailyReportCopyEndDate,
      dailyReportWorkId,
      dailyReportProductId,
      departmentId,
      teamId,

      works,
      clients,
      products,

      workId,
      clientId,
      productId,

      quantity,

      visibleNew,
      visibleNewWorks,
      visibleNewProducts,

      visibleEdit,
      visibleEditWorks,
      visibleEditProducts,

      isCopyModal,
    } = this.state;

    const filteredDepartments = departments.filter(
      x => x.departmentId === departmentId
    );
    let teams: Team[] = [];
    if (filteredDepartments.length) {
      teams = filteredDepartments[0].teams;
    }

    const isNewDuplicated = !!dailyReports
      // TODO : レスポンスがnullになるのでとりあえずこれで対応
      .map(x => ({
        ...x,
        department: x.department || undefined,
        team: x.team || undefined,
      }))
      .filter(
        x =>
          format(x.dailyReportDate, 'YYYY-MM-DD') ===
          format(dailyReportDate, 'YYYY-MM-DD')
      )
      .filter(x =>
        x.department
          ? x.department.departmentId === departmentId
          : !departmentId
      )
      .filter(x => (x.team ? x.team.teamId === teamId : !teamId)).length;

    const isEditDuplicated = !!dailyReports
      // TODO : レスポンスがnullになるのでとりあえずこれで対応
      .map(x => ({
        ...x,
        department: x.department || undefined,
        team: x.team || undefined,
      }))
      .filter(x => x.dailyReportId !== dailyReportId)
      .filter(
        x =>
          format(x.dailyReportDate, 'YYYY-MM-DD') ===
          format(dailyReportDate, 'YYYY-MM-DD')
      )
      .filter(x =>
        x.department
          ? x.department.departmentId === departmentId
          : !departmentId
      )
      .filter(x => (x.team ? x.team.teamId === teamId : !teamId)).length;

    const isNewWorkDuplicated = dailyReport
      ? !!dailyReport.works
          // TODO : レスポンスがnullになるのでとりあえずこれで対応
          .map(x => ({
            ...x,
            dailyReportProductId: x.dailyReportProductId || undefined,
            clientId: x.clientId || undefined,
          }))
          .filter(
            x =>
              x.workId === workId &&
              x.dailyReportProductId === dailyReportProductId &&
              x.clientId === clientId
          ).length
      : false;

    const isEditWorkDuplicated = dailyReport
      ? !!dailyReport.works
          // TODO : レスポンスがnullになるのでとりあえずこれで対応
          .map(x => ({
            ...x,
            dailyReportProductId: x.dailyReportProductId || undefined,
            clientId: x.clientId || undefined,
          }))
          .filter(x => x.dailyReportWorkId !== dailyReportWorkId)
          .filter(
            x =>
              x.workId === workId &&
              x.dailyReportProductId === dailyReportProductId &&
              x.clientId === clientId
          ).length
      : false;

    const isNewProductDuplicated = dailyReport
      ? !!dailyReport.products.filter(x => x.productId === productId).length
      : false;

    const isEditProductDuplicated = dailyReport
      ? !!dailyReport.products
          .filter(x => x.dailyReportProductId !== dailyReportProductId)
          .filter(x => x.productId === productId).length
      : false;

    return (
      <>
        <Button
          onClick={() => {
            this.setState({ visibleNew: true });
          }}
          type="primary"
          style={{ marginBottom: 16 }}
        >
          追加
        </Button>
        <form
          noValidate
          className={classes.container}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TextField
            id="date"
            label="日報日付 (から)"
            type="date"
            value={from}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.onChangeFromToDate('from')}
          />
          <span>〜</span>
          <TextField
            id="date"
            label="日報日付 (まで)"
            type="date"
            value={to}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.onChangeFromToDate('to')}
          />
          <Button onClick={this.handleFetch}>検索</Button>
        </form>
        <Table
          // @ts-ignore
          columns={this.columns}
          dataSource={dailyReports}
          rowKey="dailyReportId"
        />

        {/* 日報の追加・編集・コピー */}
        <DailyReportAddEditCopyModal
          dailyReports={dailyReports}
          departments={departments}
          teams={teams}
          works={works}
          clients={clients}
          products={products}
          dailyReportDate={dailyReportDate}
          dailyReportCopyStartDate={dailyReportCopyStartDate}
          dailyReportCopyEndDate={dailyReportCopyEndDate}
          departmentId={departmentId}
          teamId={teamId}
          dailyReport={dailyReport}
          remarks={remarks}
          visibleNew={visibleNew}
          visibleEdit={visibleEdit}
          isNewDuplicated={isNewDuplicated}
          isEditDuplicated={isEditDuplicated}
          isCopyModal={isCopyModal}
          onChangeDate={this.onChangeDate}
          onChangeCopyStartDate={this.onChangeCopyStartDate}
          onChangeCopyEndDate={this.onChangeCopyEndDate}
          onChangeDepartments={this.onChangeDepartments}
          onChangeTeams={this.onChangeTeams}
          onChangeRemarks={this.onChangeRemarks}
          onChangeDailyReport={this.onChangeDailyReport}
          onCannelAdd={() => {
            this.setState({
              dailyReportDate: initialState.dailyReportDate,
              remarks: initialState.remarks,
              departmentId: initialState.departmentId,
              teamId: initialState.teamId,
              visibleNew: initialState.visibleNew,
              dailyReport: initialState.dailyReport,
              isCopyModal: initialState.isCopyModal,
            });
          }}
          onCannelEdit={() => {
            this.setState({
              dailyReportDate: initialState.dailyReportDate,
              remarks: initialState.remarks,
              departmentId: initialState.departmentId,
              teamId: initialState.teamId,
              visibleEdit: initialState.visibleEdit,
            });
          }}
          handlePost={this.handlePost}
          handlePut={this.handlePut}
        />

        {/* 日報:作業の追加・編集 */}
        <DailyReportAddEditWorkModal
          works={works}
          clients={clients}
          workId={workId}
          clientId={clientId}
          dailyReportProductId={dailyReportProductId}
          dailyReport={dailyReport}
          visibleNew={visibleNewWorks}
          visibleEdit={visibleEditWorks}
          isNewDuplicated={isNewWorkDuplicated}
          isEditDuplicated={isEditWorkDuplicated}
          onChangeWorks={this.onChangeWorks}
          onChangeClients={this.onChangeClients}
          onChangeDailyReportProducts={this.onChangeDailyReportProducts}
          onCannelAdd={() => {
            this.setState({
              workId: initialState.workId,
              clientId: initialState.clientId,
              dailyReportProductId: initialState.dailyReportProductId,
              visibleNewWorks: initialState.visibleNewWorks,
            });
          }}
          onCannelEdit={() => {
            this.setState({
              dailyReport: initialState.dailyReport,
              dailyReportWorkId: initialState.dailyReportWorkId,
              workId: initialState.workId,
              clientId: initialState.clientId,
              dailyReportProductId: initialState.dailyReportProductId,
              visibleEditWorks: initialState.visibleEditWorks,
            });
          }}
          handleOkAdd={this.handlePostWorks}
          handleOkEdit={this.handlePutWorks}
        />

        {/* 日報:製品の追加・編集 */}
        <DailyReportAddEditProductModal
          products={products}
          productId={productId}
          quantity={quantity}
          visibleNew={visibleNewProducts}
          visibleEdit={visibleEditProducts}
          isNewDuplicated={isNewProductDuplicated}
          isEditDuplicated={isEditProductDuplicated}
          onChangeProducts={this.onChangeProducts}
          onChangeQuantity={this.onChangeQuantity}
          onCannelAdd={() => {
            this.setState({
              productId: initialState.productId,
              quantity: initialState.quantity,
              visibleNewProducts: initialState.visibleNewProducts,
            });
          }}
          onCannelEdit={() => {
            this.setState({
              productId: initialState.productId,
              quantity: initialState.quantity,
              visibleEditProducts: initialState.visibleEditProducts,
            });
          }}
          handleOkAdd={this.handlePostProducts}
          handleOkEdit={this.handlePutProducts}
        />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdminDailyReport));
