import * as React from 'react';

import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

type Props = InputProps;

export const CustomInput: React.SFC<Props> = props => (
  <Input
    {...props}
    onKeyDown={e => {
      if (props.type === 'number') {
        // e, +, - は入力できないように
        if (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) {
          e.preventDefault();
        }
      }
    }}
  />
);
