import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { AxiosError } from 'axios';
import { Button, Table, Divider, Icon, Modal, Form, Switch } from 'antd';

import { CustomInput as Input } from './components/CustomAntd';

import httpUtils from './utils/httpUtils';
import {
  PostPutWorkGroupsReq,
  DeleteWorkGroupsReq,
} from './types/apiRequest/workGroups.request';
import {
  GetWorkGroupsRes,
  WorkGroup,
} from './types/apiResponse/workGroups.response';

import { ApplicationState } from './reducers';

type E = React.ChangeEvent<HTMLInputElement>;

interface State {
  workGroups: WorkGroup[];
  visibleNew: boolean;
  visibleEdit: boolean;
  workGroupId: number;
  workGroupName: string;
}

const initialState = {
  workGroups: [],
  visibleNew: false,
  visibleEdit: false,
  workGroupId: 0,
  workGroupName: '',
};

const mapStateToProps = (state: ApplicationState) => ({
  officeId: state.baseReducer.selectedOfficeId,
});

const mapDispatchToProps = () => ({});

type Props = RouteComponentProps<{}> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class AdminWorkGroup extends React.PureComponent<Props, State> {
  columns = [
    {
      title: '作業グループ名',
      dataIndex: 'workGroupName',
      key: 'workGroupName',
      defaultSortOrder: 'ascend',
      sorter: (a: WorkGroup, b: WorkGroup) =>
        a.workGroupName < b.workGroupName ? -1 : 1,
    },
    {
      title: '表示 / 非表示',
      render: (record: WorkGroup) => (
        <Switch
          checked={!!record.visibleFlag}
          onChange={() => {
            this.handleSwitch(record);
          }}
        />
      ),
    },
    {
      title: '編集 / 削除',
      dataIndex: '',
      key: 'x',
      // @ts-ignore
      render: (record: WorkGroup) => (
        <span>
          <Icon
            type="edit"
            onClick={() => {
              this.setState({
                workGroupId: record.workGroupId,
                workGroupName: record.workGroupName,
                visibleEdit: true,
              });
            }}
          />
          <Divider type="vertical" />
          <Icon
            type="delete"
            onClick={() => {
              const handleDelete = this.handleDelete;
              Modal.confirm({
                title: 'このデータを削除しますか？',
                content: '削除すると、元に戻せません。',
                cancelText: 'キャンセル',
                onOk() {
                  handleDelete(record.workGroupId);
                },
                onCancel() {},
              });
            }}
          />
        </span>
      ),
    },
  ];

  state: State = initialState;

  handleFetch = async () => {
    const { officeId } = this.props;
    try {
      const res = await httpUtils.get(`/workGroups`, {
        params: { officeId },
      });
      const workGroups: GetWorkGroupsRes = res.data;
      this.setState({ workGroups });
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePost = async () => {
    const { officeId } = this.props;
    const { workGroups, workGroupName } = this.state;
    const req: PostPutWorkGroupsReq = {
      workGroupName,
      officeId,
    };
    try {
      await httpUtils.post(`/workGroups`, req);
      this.setState({ ...initialState, workGroups });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePut = async () => {
    const { officeId } = this.props;
    const { workGroups, workGroupId, workGroupName } = this.state;
    const req: PostPutWorkGroupsReq = {
      workGroupName,
      officeId,
    };
    try {
      await httpUtils.put(`/workGroups/${workGroupId}`, req);
      this.setState({ ...initialState, workGroups });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleDelete = async (id: number) => {
    const { officeId } = this.props;
    const req: DeleteWorkGroupsReq = {
      officeId,
    };
    try {
      await httpUtils.delete(`/workGroups/${id}`, {
        data: req,
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleSwitch = async (record: WorkGroup) => {
    const { officeId } = this.props;
    const { workGroupId } = record;
    try {
      await httpUtils.put(`/workGroups/${workGroupId}`, {
        ...record,
        officeId,
        visibleFlag: record.visibleFlag === 1 ? 0 : 1,
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  onChangeWorkGroupName = (e: E) => {
    this.setState({ workGroupName: e.target.value });
  };

  async componentDidMount() {
    const { officeId } = this.props;
    // ログインに戻す
    if (!officeId) {
      this.props.history.push('/login');
      return;
    }
    try {
      const res = await httpUtils.get(`/workGroups`, {
        params: { officeId },
      });
      const workGroups: GetWorkGroupsRes = res.data;
      this.setState({ workGroups });
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  }

  render() {
    // console.log(this.state);
    // console.log(this.props);
    const {
      workGroups,
      workGroupId,
      workGroupName,
      visibleNew,
      visibleEdit,
    } = this.state;

    const isNewDuplicated = !!workGroups.filter(
      x => x.workGroupName === workGroupName
    ).length;
    const isEditDuplicated = !!workGroups
      .filter(x => x.workGroupId !== workGroupId)
      .filter(x => x.workGroupName === workGroupName).length;

    return (
      <>
        <Button
          onClick={() => {
            this.setState({ visibleNew: true });
          }}
          type="primary"
          style={{ marginBottom: 16 }}
        >
          追加
        </Button>
        <Table
          // @ts-ignore
          columns={this.columns}
          dataSource={workGroups}
          rowKey="workGroupId"
        />
        <Modal
          title="作業グループを追加"
          visible={visibleNew}
          onOk={() => this.handlePost()}
          onCancel={() => this.setState({ ...initialState, workGroups })}
          cancelText="キャンセル"
          okButtonProps={{ disabled: !workGroupName || isNewDuplicated }}
        >
          <Form>
            <Form.Item
              validateStatus={isNewDuplicated ? 'error' : undefined}
              help={
                isNewDuplicated
                  ? '既に登録されている名前は使用できません'
                  : undefined
              }
            >
              <Input
                placeholder="作業グループ名"
                value={workGroupName}
                onChange={this.onChangeWorkGroupName}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="作業グループを編集"
          visible={visibleEdit}
          onOk={() => this.handlePut()}
          onCancel={() => this.setState({ ...initialState, workGroups })}
          cancelText="キャンセル"
          okButtonProps={{ disabled: !workGroupName || isEditDuplicated }}
        >
          <Form>
            <Form.Item
              validateStatus={isEditDuplicated ? 'error' : undefined}
              help={
                isEditDuplicated
                  ? '既に登録されている名前は使用できません'
                  : undefined
              }
            >
              <Input
                placeholder="作業グループ名"
                value={workGroupName}
                onChange={this.onChangeWorkGroupName}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminWorkGroup);
