import axios, { AxiosError } from 'axios';
// import keys from 'lodash/keys';
import awsUtils from './awsUtils';
import { message } from 'antd';

const httpUtils = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

httpUtils.interceptors.request.use(async config => {
  try {
    const token = await awsUtils.getUserToken();
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    // TODO: tokenが無かった場合のハンドリング
  } catch (err) {
    console.log(err);
  }
  return config;
});

httpUtils.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    // Todo : 良い方法を考える
    if (err.response) {
      const axiosError: AxiosError = err;
      if (axiosError.response && axiosError.response.status === 400) {
        // 使用済削除のエラーのハンドリング
        message.error('使用済の項目は削除できません');
        return;
      }
      if (axiosError.response && axiosError.response.status === 401) {
        // トークンの検証エラーのハンドリング
        message.error(
          'エラーが発生しました。解決しない場合は、ログアウトし再ログインしてください。'
        );
        return;
      }
    }
    return Promise.reject(err);
  }
);

export default httpUtils;
