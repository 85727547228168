import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { User } from './../types/apiResponse/users.response';
import * as actions from './../actions/baseAction';

export interface BaseState {
  user?: User;
  selectedOfficeId: number;
}

const initialBaseState: BaseState = {
  user: undefined,
  selectedOfficeId: 0,
};

export default reducerWithInitialState(initialBaseState)
  .cases([actions.setUser], (state, payload) => ({
    ...state,
    user: payload,
    selectedOfficeId:
      state.selectedOfficeId || payload.companies[0].offices[0].officeId,
  }))
  .cases([actions.selectOfficeId], (state, payload) => ({
    ...state,
    selectedOfficeId: payload,
  }));
