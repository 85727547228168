import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { AxiosError } from 'axios';
import { format, parse, isAfter, isBefore, addWeeks } from 'date-fns';
import ja from 'date-fns/locale/ja';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import TextField from '@material-ui/core/TextField';
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles';

import { Button, Tooltip, Icon } from 'antd';

import httpUtils from './utils/httpUtils';

import { Sale } from './types/apiResponse/sales.response';
import { PostSalesReq } from './types/apiRequest/sales.request';

import { ApplicationState } from './reducers';

const defaultSorted = [
  {
    dataField: 'summaryDate',
    order: 'desc',
  },
];

const columns = [
  {
    dataField: 'saleId',
    text: 'ID',
    hidden: true,
    csvExport: false,
  },
  {
    dataField: 'summaryDate',
    text: '日報日付',
    sort: true,
    formatter: (summaryDate: string) =>
      format(parse(summaryDate), 'YYYY/MM/DD(dd)', {
        locale: ja,
      }),
  },
  {
    dataField: 'clientName',
    text: '取引先',
    sort: true,
    formatter: (_: any, row: Sale) =>
      row.saleWorkerCosts
        .filter(x => !!x.clientId)
        .map(x => x.clientName)
        .filter((x, i, self) => self.indexOf(x) === i)
        .map(clientName => <div key={clientName}>{clientName}</div>),
    csvFormatter: (_: any, row: Sale) =>
      row.saleWorkerCosts
        .filter(x => !!x.clientId)
        .map(x => x.clientName)
        .filter((x, i, self) => self.indexOf(x) === i)
        .join('\n'),
  },
  {
    dataField: 'productName',
    text: '製品',
    sort: true,
  },
  {
    dataField: 'unitPrice',
    text: '単価(円)',
    sort: true,
    style: {
      textAlign: 'right',
    },
  },
  {
    dataField: 'quantity',
    text: '数量',
    sort: true,
    style: {
      textAlign: 'right',
    },
  },
  {
    dataField: 'quantityUnit',
    text: '数量単位',
    sort: true,
  },
  {
    dataField: 'sale',
    text: '売上(円)',
    sort: true,
    style: {
      textAlign: 'right',
    },
    formatter: (_: any, row: Sale) => Math.round(row.unitPrice * row.quantity),
    csvFormatter: (_: any, row: Sale) =>
      Math.round(row.unitPrice * row.quantity),
  },
  {
    dataField: 'workersNum',
    text: '人数',
    sort: true,
    style: {
      textAlign: 'right',
    },
    formatter: (_: any, row: Sale) =>
      row.saleWorkerCosts
        .map(x => x.workerId)
        .filter((x, i, self) => self.indexOf(x) === i).length,
    csvFormatter: (_: any, row: Sale) =>
      row.saleWorkerCosts
        .map(x => x.workerId)
        .filter((x, i, self) => self.indexOf(x) === i).length,
  },
  {
    dataField: 'operatingTime',
    text: '総作業時間(分)',
    sort: true,
    style: {
      textAlign: 'right',
    },
    formatter: (_: any, row: Sale) =>
      row.saleWorkerCosts
        .map(x1 => Math.round(x1.operatingTime / 60))
        .reduce((x1, x2) => x1 + x2, 0),
    csvFormatter: (_: any, row: Sale) =>
      row.saleWorkerCosts
        .map(x1 => Math.round(x1.operatingTime / 60))
        .reduce((x1, x2) => x1 + x2, 0),
  },
  {
    dataField: 'workerCosts',
    text: '総人件費(円)',
    sort: true,
    style: {
      textAlign: 'right',
    },
    formatter: (_: any, row: Sale) =>
      row.saleWorkerCosts
        .map(x => Math.round((x.operatingTime / (60 * 60)) * x.payment))
        .reduce((x1, x2) => x1 + x2, 0),
    csvFormatter: (_: any, row: Sale) =>
      row.saleWorkerCosts
        .map(x => Math.round((x.operatingTime / (60 * 60)) * x.payment))
        .reduce((x1, x2) => x1 + x2, 0),
  },
  {
    dataField: 'grossProfit',
    text: '粗利(円)',
    sort: true,
    style: {
      textAlign: 'right',
    },
    formatter: (_: any, row: Sale) =>
      // 売上
      Math.round(row.unitPrice * row.quantity) -
      // 総人件費
      row.saleWorkerCosts
        .map(x => Math.round((x.operatingTime / (60 * 60)) * x.payment))
        .reduce((x1, x2) => x1 + x2, 0),
    csvFormatter: (_: any, row: Sale) =>
      // 売上
      Math.round(row.unitPrice * row.quantity) -
      // 総人件費
      row.saleWorkerCosts
        .map(x => Math.round((x.operatingTime / (60 * 60)) * x.payment))
        .reduce((x1, x2) => x1 + x2, 0),
  },
  {
    dataField: 'humanProductivity',
    text: '人時生産性(円/分)',
    sort: true,
    style: {
      textAlign: 'right',
    },
    formatter: (_: any, row: Sale) => {
      // 総作業時間(分)
      const operatingTime = row.saleWorkerCosts
        .map(x1 => Math.round(x1.operatingTime / 60))
        .reduce((x1, x2) => x1 + x2, 0);
      // 総作業時間が0の場合
      if (operatingTime === 0) {
        return '';
      }
      return Math.round(
        // 売上
        (Math.round(row.unitPrice * row.quantity) -
          // 総人件費
          row.saleWorkerCosts
            .map(x => Math.round((x.operatingTime / (60 * 60)) * x.payment))
            .reduce((x1, x2) => x1 + x2, 0)) /
          operatingTime
      );
    },
    csvFormatter: (_: any, row: Sale) => {
      // 総作業時間(分)
      const operatingTime = row.saleWorkerCosts
        .map(x1 => Math.round(x1.operatingTime / 60))
        .reduce((x1, x2) => x1 + x2, 0);
      // 総作業時間が0の場合
      if (operatingTime === 0) {
        return '';
      }
      return Math.round(
        // 売上
        (Math.round(row.unitPrice * row.quantity) -
          // 総人件費
          row.saleWorkerCosts
            .map(x => Math.round((x.operatingTime / (60 * 60)) * x.payment))
            .reduce((x1, x2) => x1 + x2, 0)) /
          operatingTime
      );
    },
  },
  {
    dataField: 'changeFlag',
    text: '',
    csvExport: false,
    formatter: (changeFlag: number) =>
      changeFlag ? (
        <Tooltip title="情報が古い可能性があります。">
          <Icon type="warning" theme="twoTone" twoToneColor="#faad14" />
        </Tooltip>
      ) : (
        <div />
      ),
  },
];

type E = React.ChangeEvent<HTMLInputElement>;

const styles = (theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: 10,
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
    },
  });

const mapStateToProps = (state: ApplicationState) => ({
  officeId: state.baseReducer.selectedOfficeId,
});
const mapDispatchToProps = () => ({});

type Props = RouteComponentProps<{}> &
  WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface State {
  sales: Sale[];
  summaryDate: string;
  from: string;
  to: string;
}

const initialState: State = {
  sales: [],
  summaryDate: '',
  from: format(addWeeks(new Date(), -4), 'YYYY-MM-DD'),
  to: format(addWeeks(new Date(), 1), 'YYYY-MM-DD'),
};

class TablePL extends React.PureComponent<Props, State> {
  state: State = initialState;

  onChangeDate = (e: E) => {
    this.setState({ summaryDate: e.target.value });
  };

  onChangeFromToDate = (fromTo: string) => (e: E) => {
    const date = e.target.value;
    if (fromTo === 'from') {
      if (isAfter(date, this.state.to)) {
        this.setState({ from: date, to: date });
        return;
      }
      this.setState({ from: date });
    }
    if (fromTo === 'to') {
      if (isBefore(date, this.state.from)) {
        this.setState({ from: date, to: date });
        return;
      }
      this.setState({ to: date });
    }
  };

  handleFetch = async () => {
    const { officeId } = this.props;
    const { from, to } = this.state;
    try {
      const res = await httpUtils.get(`/sales`, {
        params: {
          officeId,
          from,
          to,
        },
      });
      const sales: Sale[] = res.data;
      this.setState({ sales });
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleSave = async () => {
    const { officeId } = this.props;
    const { summaryDate } = this.state;
    if (!summaryDate) {
      return;
    }
    try {
      const req: PostSalesReq = {
        officeId,
        summaryDate,
      };
      await httpUtils.post(`/sales`, req);
      this.setState({ summaryDate: initialState.summaryDate });
      // データを再取得
      this.handleFetch();
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  componentDidMount() {
    this.handleFetch();
  }

  render() {
    const { classes } = this.props;
    const { sales, summaryDate, from, to } = this.state;

    return (
      <>
        <form
          noValidate
          className={classes.container}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TextField
            id="date"
            label="集計日"
            type="date"
            value={summaryDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.onChangeDate}
          />
          <Button onClick={this.handleSave} disabled={!this.state.summaryDate}>
            集計
          </Button>
        </form>
        <form
          noValidate
          className={classes.container}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TextField
            id="date"
            label="日報日付 (から)"
            type="date"
            value={from}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.onChangeFromToDate('from')}
          />
          <span>〜</span>
          <TextField
            id="date"
            label="日報日付 (まで)"
            type="date"
            value={to}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.onChangeFromToDate('to')}
          />
          <Button onClick={this.handleFetch}>検索</Button>
        </form>
        {/* 
          // @ts-ignore */}
        <ToolkitProvider
          keyField="saleId"
          data={sales}
          columns={columns}
          exportCSV={{
            noAutoBOM: false,
            fileName: `売上集計.csv`,
          }}
        >
          {(props: any) => (
            <div>
              <Button
                type="primary"
                // @ts-ignore
                onClick={() => props.csvProps.onExport()}
                disabled={!sales.length}
              >
                CSVダウンロード
              </Button>
              <hr />
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                defaultSorted={defaultSorted}
                rowStyle={{
                  overflowWrap: 'break-word',
                  fontSize: '0.8rem',
                }}
                noDataIndication="該当するデータがありませんでした"
                pagination={paginationFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TablePL));
