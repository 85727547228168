import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { AxiosError } from 'axios';
import { Button, Table, Divider, Icon, Modal, Form, Switch } from 'antd';

import { CustomInput as Input } from './components/CustomAntd';
import httpUtils from './utils/httpUtils';
import { PostPutClientsReq } from './types/apiRequest/clients.request';
import { GetClientsRes, Client } from './types/apiResponse/clients.response';
import { ApplicationState } from './reducers';

type E = React.ChangeEvent<HTMLInputElement>;

interface State {
  clients: Client[];
  visibleNew: boolean;
  visibleEdit: boolean;
  clientId: number;
  clientName: string;
}

const initialState = {
  clients: [],
  visibleNew: false,
  visibleEdit: false,
  clientId: 0,
  clientName: '',
};

const mapStateToProps = (state: ApplicationState) => ({
  officeId: state.baseReducer.selectedOfficeId,
});

const mapDispatchToProps = () => ({});

type Props = RouteComponentProps<{}> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class AdminClient extends React.PureComponent<Props, State> {
  columns = [
    {
      title: '取引先名',
      dataIndex: 'clientName',
      key: 'clientName',
      defaultSortOrder: 'ascend',
      sorter: (a: Client, b: Client) => (a.clientName < b.clientName ? -1 : 1),
    },
    {
      title: '表示/非表示',
      render: (record: Client) => {
        return (
          <Switch
            checked={!!record.visibleFlag}
            onChange={() => {
              this.handleSwitch(record);
            }}
          />
        );
      },
    },
    {
      title: '編集 / 削除',
      dataIndex: '',
      key: 'x',
      // @ts-ignore
      render: (record: Client) => (
        <span>
          <Icon
            type="edit"
            onClick={() => {
              this.setState({
                clientId: record.clientId,
                clientName: record.clientName,
                visibleEdit: true,
              });
            }}
          />
          <Divider type="vertical" />
          <Icon
            type="delete"
            onClick={() => {
              const handleDelete = this.handleDelete;
              Modal.confirm({
                title: 'このデータを削除しますか？',
                content: '削除すると、元に戻せません。',
                cancelText: 'キャンセル',
                onOk() {
                  handleDelete(record.clientId);
                },
                onCancel() {},
              });
            }}
          />
        </span>
      ),
    },
  ];

  state: State = initialState;

  handleFetch = async () => {
    const { officeId } = this.props;
    try {
      const res = await httpUtils.get(`/clients`, {
        params: { officeId },
      });
      const clients: GetClientsRes = res.data;
      this.setState({ clients });
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePost = async () => {
    const { officeId } = this.props;
    const { clients, clientName } = this.state;
    const req: PostPutClientsReq = {
      clientName,
      officeId,
      // clientId: 1
    };
    try {
      await httpUtils.post(`/clients`, req);
      this.setState({ ...initialState, clients });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePut = async () => {
    const { officeId } = this.props;
    const { clients, clientId, clientName } = this.state;
    const req: PostPutClientsReq = {
      clientName,
      officeId,
    };
    try {
      await httpUtils.put(`/clients/${clientId}`, req);
      this.setState({ ...initialState, clients });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleDelete = async (id: number) => {
    const { officeId } = this.props;
    try {
      await httpUtils.delete(`/clients/${id}`, {
        data: {
          officeId,
        },
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleSwitch = async (record: Client) => {
    const { officeId } = this.props;
    const { clientId } = record;
    try {
      await httpUtils.put(`/clients/${clientId}`, {
        ...record,
        officeId,
        visibleFlag: record.visibleFlag === 1 ? 0 : 1,
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  onChangeClientName = (e: E) => {
    this.setState({ clientName: e.target.value });
  };

  async componentDidMount() {
    const { officeId } = this.props;
    // ログインに戻す
    if (!officeId) {
      this.props.history.push('/login');
      return;
    }
    try {
      const res = await httpUtils.get(`/clients`, {
        params: { officeId },
      });
      const clients: GetClientsRes = res.data;
      this.setState({ clients });
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  }

  render() {
    const {
      clients,
      clientId,
      clientName,
      visibleNew,
      visibleEdit,
    } = this.state;

    const isNewDuplicated = !!clients.filter(x => x.clientName === clientName)
      .length;
    const isEditDuplicated = !!clients
      .filter(x => x.clientId !== clientId)
      .filter(x => x.clientName === clientName).length;

    return (
      <>
        <Button
          onClick={() => {
            this.setState({ visibleNew: true });
          }}
          type="primary"
          style={{ marginBottom: 16 }}
        >
          追加
        </Button>
        <Table
          // @ts-ignore
          columns={this.columns}
          dataSource={clients}
          rowKey="clientId"
        />
        <Modal
          title="取引先を追加"
          visible={visibleNew}
          onOk={() => this.handlePost()}
          onCancel={() => this.setState({ ...initialState, clients })}
          cancelText="キャンセル"
          okButtonProps={{ disabled: !clientName || isNewDuplicated }}
        >
          <Form>
            <Form.Item
              validateStatus={isNewDuplicated ? 'error' : undefined}
              help={
                isNewDuplicated
                  ? '既に登録されている名前は使用できません'
                  : undefined
              }
            >
              <Input
                placeholder="取引先名"
                value={clientName}
                onChange={this.onChangeClientName}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="取引先を編集"
          visible={visibleEdit}
          onOk={() => this.handlePut()}
          onCancel={() => this.setState({ ...initialState, clients })}
          cancelText="キャンセル"
          okButtonProps={{ disabled: !clientName || isEditDuplicated }}
        >
          <Form>
            <Form.Item
              validateStatus={isEditDuplicated ? 'error' : undefined}
              help={
                isEditDuplicated
                  ? '既に登録されている名前は使用できません'
                  : undefined
              }
            >
              <Input
                placeholder="取引先名"
                value={clientName}
                onChange={this.onChangeClientName}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminClient);
