import { combineReducers } from 'redux';
// import { History } from 'history';

import baseReducer, { BaseState } from './baseReducer';

export interface ApplicationState {
  baseReducer: BaseState;
  // router: RouterState;
}

const appReducer = combineReducers({
  baseReducer,
  // router: connectRouter(history),
});

// @ts-ignore
const rootReducer = (state, action) => {
  if (action.type === '@@base/INIT_STATE') {
    // tslint:disable-next-line:no-parameter-reassignment
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
