import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { routerMiddleware } from 'connected-react-router';
// import { createBrowserHistory } from 'history';

import reducers from './../reducers';
import sagas from '../sagas';

const sagaMiddleware = createSagaMiddleware();
// export const history = createBrowserHistory();

const persistConfig: PersistConfig = {
  storage,
  key: 'root',
  whitelist: ['baseReducer'],
  // version: Number(String(Config.APP_VERSION).replace(/\./g, '')), // APP_VERSION=1.3.0 => 130 に変換
  // timeout: 10000,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const createEnhancer = () => {
//   let composeEnhancers = compose;
//   if (
//     // process.env.NODE_ENV !== 'production' &&
//     typeof window !== 'undefined' &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//   ) {
//     composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
//   }

//   return composeEnhancers(
//     applyMiddleware(
//       sagaMiddleware
//       // routerMiddleware(history)
//     )
//   );
// };

export default () => {
  const store = createStore(
    // reducers(history),
    persistedReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );
  const persistor = persistStore(store);
  sagaMiddleware.run(sagas);

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default;
      store.replaceReducer(nextReducer);
    });
  }

  return { store, persistor };
};
