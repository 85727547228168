import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { AxiosError } from 'axios';
import moment from 'moment';
import { format, parse, isAfter, isBefore, startOfToday } from 'date-fns';
import ja from 'date-fns/locale/ja';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import TextField from '@material-ui/core/TextField';
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles';

import {
  Button,
  Divider,
  Icon,
  Modal,
  DatePicker,
  Select,
  Form,
  Checkbox,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import httpUtils from './utils/httpUtils';

import { Activity } from './types/apiResponse/activities.response';
import { PostPutActivitiesReq } from './types/apiRequest/activities.request';
import { GetWorkersRes, Worker } from './types/apiResponse/workers.response';
import { GetWorksRes, Work } from './types/apiResponse/works.response';
import { GetProductsRes, Product } from './types/apiResponse/products.response';
import { GetClientsRes, Client } from './types/apiResponse/clients.response';
import {
  GetWorkGroupsRes,
  WorkGroup,
} from './types/apiResponse/workGroups.response';
import { GetKbnsRes, Kbn } from './types/apiResponse/system.response';
import {
  GetDailyReportsRes,
  DailyReport,
} from './types/apiResponse/dailyReports.response';
import {
  GetDepartmentsRes,
  Department,
} from './types/apiResponse/departments.response';

import { ApplicationState } from './reducers';

const sortFunc = (
  a: number | string | Date,
  b: number | string | Date,
  order: string,
  _: string,
  rowA: Activity,
  rowB: Activity
) => {
  if (order === 'asc') {
    return a > b
      ? 1
      : a < b
      ? -1
      : isAfter(rowA.startAt, rowB.startAt)
      ? 1
      : -1;
  }
  return b > a ? 1 : b < a ? -1 : isAfter(rowA.startAt, rowB.startAt) ? 1 : -1;
};

type E = React.ChangeEvent<HTMLInputElement>;

const { Option } = Select;

const styles = (theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: 10,
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
    },
  });

const mapStateToProps = (state: ApplicationState) => ({
  officeId: state.baseReducer.selectedOfficeId,
});

const mapDispatchToProps = () => ({});

type Props = RouteComponentProps<{}> &
  WithStyles<typeof styles> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface State {
  from: string;
  to: string;
  activities: Activity[];
  dailyReports: DailyReport[];
  workers: Worker[];
  works: Work[];
  products: Product[];
  clients: Client[];
  workGroups: WorkGroup[];
  kbns: Kbn[];
  departments: Department[];
  activityId: number;
  startAt: Date;
  endAt?: Date;
  dailyReportId: number;
  dailyReportWorkId: number;
  workerId: number;
  workerCode?: string;
  filterEndAt: boolean;
  filterWorkId?: number;
  filterProductId?: number;
  filterClientId?: number;
  filterWorkGroupId?: number;
  filterWorkerKbnId?: number;
  filterDepartmentId?: number;
  visibleNew: boolean;
  visibleEdit: boolean;
}

const initialState = {
  from: format(new Date(), 'YYYY-MM-DD'),
  to: format(new Date(), 'YYYY-MM-DD'),
  activities: [],
  dailyReports: [],
  workers: [],
  works: [],
  products: [],
  clients: [],
  workGroups: [],
  kbns: [],
  departments: [],
  activityId: 0,
  startAt: startOfToday(),
  endAt: undefined,
  dailyReportId: 0,
  dailyReportWorkId: 0,
  workerId: 0,
  workerCode: undefined,
  filterEndAt: false,
  filterWorkId: undefined,
  filterProductId: undefined,
  filterClientId: undefined,
  filterWorkGroupId: undefined,
  filterWorkerKbnId: undefined,
  filterDepartmentId: undefined,
  visibleNew: false,
  visibleEdit: false,
};

class TableActivity extends React.PureComponent<Props, State> {
  defaultSorted = [
    {
      dataField: 'dailyReportDate',
      order: 'desc',
    },
  ];
  columns = [
    {
      dataField: 'activityId',
      text: 'ID',
      hidden: true,
      csvExport: false,
    },
    {
      dataField: 'dailyReportDate',
      text: '日報',
      sort: true,
      sortFunc: sortFunc,
      formatter: (dailyReportDate: Date) =>
        format(dailyReportDate, 'YYYY/MM/DD(dd)', {
          locale: ja,
        }),
      csvFormatter: (dailyReportDate: Date) =>
        format(dailyReportDate, 'YYYY-MM-DD', {
          locale: ja,
        }),
    },
    {
      dataField: 'workName',
      text: '作業',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'startAt',
      text: '開始時刻',
      sort: true,
      formatter: (startAt: Date) =>
        format(startAt, 'YYYY/MM/DD(dd) HH:mm', {
          locale: ja,
        }),
    },
    {
      dataField: 'endAt',
      text: '終了時刻',
      sort: true,
      formatter: (endAt?: Date) =>
        endAt &&
        format(endAt, 'YYYY/MM/DD(dd) HH:mm', {
          locale: ja,
        }),
      csvFormatter: (endAt?: Date) => endAt || '',
    },
    {
      dataField: 'workerName',
      text: '作業者',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'workerCode',
      text: '従業員コード',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'workerKbnName',
      text: '従業員区分',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'operatingTime',
      text: '作業時間(分)',
      sort: true,
      sortFunc: sortFunc,
      style: {
        textAlign: 'right',
      },
      formatter: (_: any, row: Activity) =>
        row.operatingTime ? Math.round(row.operatingTime / 60) : '',
      csvFormatter: (_: any, row: Activity) =>
        row.operatingTime ? Math.round(row.operatingTime / 60) : '',
    },
    {
      dataField: 'payment',
      text: '人件費(円)',
      sort: true,
      sortFunc: sortFunc,
      style: {
        textAlign: 'right',
      },
      formatter: (_: any, row: Activity) =>
        row.operatingTime
          ? Math.round(row.payment * (row.operatingTime / 3600))
          : '',
      csvFormatter: (_: any, row: Activity) =>
        row.operatingTime
          ? Math.round(row.payment * (row.operatingTime / 3600))
          : '',
    },
    {
      dataField: 'productName',
      text: '製品名',
      sort: true,
      sortFunc: sortFunc,
      formatter: (productName: string) => productName || '',
      csvFormatter: (productName: string) => productName || '',
    },
    {
      dataField: 'clientName',
      text: '取引先',
      sort: true,
      sortFunc: sortFunc,
      formatter: (clientName: string) => clientName || '',
      csvFormatter: (clientName: string) => clientName || '',
    },
    {
      dataField: 'departmentName',
      text: '部門',
      sort: true,
      sortFunc: sortFunc,
      formatter: (departmentName: string) => departmentName || '',
      csvFormatter: (departmentName: string) => departmentName || '',
    },
    {
      dataField: 'teamName',
      text: 'チーム',
      sort: true,
      sortFunc: sortFunc,
      formatter: (teamName: string) => teamName || '',
      csvFormatter: (teamName: string) => teamName || '',
    },
    {
      dataField: 'workGroupName',
      text: '作業グループ',
      sort: true,
      sortFunc: sortFunc,
      formatter: (workGroupName: string) => workGroupName || '',
      csvFormatter: (workGroupName: string) => workGroupName || '',
    },
    {
      dataField: 'editDelete',
      text: '編集 / 削除',
      csvExport: false,
      formatter: (_: any, row: Activity) => (
        <span>
          <Icon
            type="edit"
            onClick={async () => {
              // 取得してからでないとエラーが起きる場合があるのでawaitさせる
              await this.handleFetchDailyReports();
              this.setState({
                activityId: row.activityId,
                startAt: row.startAt,
                endAt: row.endAt,
                dailyReportId: row.dailyReportId,
                dailyReportWorkId: row.dailyReportWorkId,
                workerId: row.workerId,
                workerCode: row.workerCode,
                visibleEdit: true,
              });
            }}
          />
          <Divider type="vertical" />
          <Icon
            type="delete"
            onClick={() => {
              const handleDelete = this.handleDelete;
              Modal.confirm({
                title: 'このデータを削除しますか？',
                content: '削除すると、元に戻せません。',
                cancelText: 'キャンセル',
                onOk() {
                  handleDelete(row.activityId);
                },
                onCancel() {},
              });
            }}
          />
        </span>
      ),
    },
  ];

  state: State = initialState;

  onChangeDate = (fromTo: string) => (e: E) => {
    const date = e.target.value;
    if (fromTo === 'from') {
      if (isAfter(date, this.state.to)) {
        this.setState({ from: date, to: date });
        return;
      }
      this.setState({ from: date });
    }
    if (fromTo === 'to') {
      if (isBefore(date, this.state.from)) {
        this.setState({ from: date, to: date });
        return;
      }
      this.setState({ to: date });
    }
  };

  handleFetch = async () => {
    const { officeId } = this.props;
    const { from, to } = this.state;
    try {
      const [
        res1,
        res2,
        res3,
        res4,
        res5,
        res6,
        res7,
        res8,
      ] = await Promise.all([
        httpUtils.get(`/activities`, {
          params: { officeId, from, to },
        }),
        httpUtils.get(`/workers`, {
          params: { officeId },
        }),
        httpUtils.get(`/works`, {
          params: { officeId },
        }),
        httpUtils.get(`/products`, {
          params: { officeId },
        }),
        httpUtils.get(`/clients`, {
          params: { officeId },
        }),
        httpUtils.get(`/workGroups`, {
          params: { officeId },
        }),
        httpUtils.get(`/system/kbns`, {
          params: { officeId },
        }),
        httpUtils.get(`/departments`, {
          params: { officeId },
        }),
      ]);
      const activities: Activity[] = res1.data;
      const workers: GetWorkersRes = res2.data;
      const works: GetWorksRes = res3.data;
      const products: GetProductsRes = res4.data;
      const clients: GetClientsRes = res5.data;
      const workGroups: GetWorkGroupsRes = res6.data;
      const kbns: GetKbnsRes = res7.data;
      const departments: GetDepartmentsRes = res8.data;
      this.setState({
        activities,
        workers,
        works,
        products,
        clients,
        workGroups,
        kbns,
        departments,
      });
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleFetchDailyReports = async () => {
    const { officeId } = this.props;
    try {
      const { data } = await httpUtils.get(`/dailyReports`, {
        params: { officeId },
      });
      const dailyReports: GetDailyReportsRes = data;
      this.setState({
        dailyReports,
      });
    } catch (error) {
      // Todo : 良い方法を考える
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePost = async () => {
    const { officeId } = this.props;
    const {
      activities,
      startAt,
      endAt,
      dailyReportId,
      dailyReportWorkId,
      workerId,
      workerCode,
      from,
      to,
      filterEndAt,
      filterWorkId,
      filterProductId,
      filterClientId,
      filterWorkGroupId,
      filterWorkerKbnId,
      filterDepartmentId,
    } = this.state;
    const req: PostPutActivitiesReq = {
      startAt,
      endAt,
      dailyReportId,
      dailyReportWorkId,
      workerId,
      workerCode,
      officeId,
    };
    try {
      await httpUtils.post(`/activities`, req);
      this.setState({
        ...initialState,
        activities,
        from,
        to,
        filterEndAt,
        filterWorkId,
        filterProductId,
        filterClientId,
        filterWorkGroupId,
        filterWorkerKbnId,
        filterDepartmentId,
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handlePut = async () => {
    const { officeId } = this.props;
    const {
      activities,
      activityId,
      startAt,
      endAt,
      dailyReportId,
      dailyReportWorkId,
      workerId,
      workerCode,
      from,
      to,
      filterEndAt,
      filterWorkId,
      filterProductId,
      filterClientId,
      filterWorkGroupId,
      filterWorkerKbnId,
      filterDepartmentId,
    } = this.state;
    const req: PostPutActivitiesReq = {
      startAt,
      endAt,
      dailyReportId,
      dailyReportWorkId,
      workerId,
      workerCode,
      officeId,
    };
    try {
      await httpUtils.put(`/activities/${activityId}`, req);
      this.setState({
        ...initialState,
        activities,
        from,
        to,
        filterEndAt,
        filterWorkId,
        filterProductId,
        filterClientId,
        filterWorkGroupId,
        filterWorkerKbnId,
        filterDepartmentId,
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  handleDelete = async (activityId: number) => {
    const { officeId } = this.props;
    try {
      await httpUtils.delete(`/activities/${activityId}`, {
        data: {
          officeId,
        },
      });
      this.handleFetch();
    } catch (error) {
      const err: AxiosError = error;
      if (err.response) {
        const { status, statusText } = err.response;
        console.log(`Error! HTTP Status: ${status} ${statusText}`);
      }
    }
  };

  onChangeStartAt = (_: any, dateString: string) => {
    if (dateString) {
      if (!this.state.endAt || isBefore(parse(dateString), this.state.endAt)) {
        this.setState({
          startAt: parse(dateString),
        });
      }
    }
  };

  onChangeEndAt = (_: any, dateString: string) => {
    if (isAfter(parse(dateString), this.state.startAt)) {
      this.setState({
        endAt: dateString ? parse(dateString) : undefined,
      });
    }
  };

  onChangeDailyReports = (value?: string) => {
    this.setState({
      dailyReportId: value ? parseInt(value, 10) : initialState.dailyReportId,
      dailyReportWorkId: initialState.dailyReportWorkId,
    });
  };

  onChangeDailyReportWorks = (value?: string) => {
    this.setState({
      dailyReportWorkId: value
        ? parseInt(value, 10)
        : initialState.dailyReportWorkId,
    });
  };

  onChangeWorkers = (value?: string) => {
    this.setState({
      workerId: value ? parseInt(value, 10) : initialState.workerId,
    });
  };

  onChangeWorkerCode = (e: E) => {
    this.setState({ workerCode: e.target.value });
  };

  onChangeFilterEndAt = (e: CheckboxChangeEvent) => {
    this.setState({
      filterEndAt: e.target.checked,
    });
  };

  onChangeFilterWorks = (value?: string) => {
    this.setState({
      filterWorkId: value ? parseInt(value, 10) : initialState.filterWorkId,
    });
  };

  onChangeFilterProducts = (value?: string) => {
    this.setState({
      filterProductId: value
        ? parseInt(value, 10)
        : initialState.filterProductId,
    });
  };

  onChangeFilterClients = (value?: string) => {
    this.setState({
      filterClientId: value ? parseInt(value, 10) : initialState.filterClientId,
    });
  };

  onChangeFilterWorkGroups = (value?: string) => {
    this.setState({
      filterWorkGroupId: value
        ? parseInt(value, 10)
        : initialState.filterWorkGroupId,
    });
  };

  onChangeFilterWorkerKbns = (value?: string) => {
    this.setState({
      filterWorkerKbnId: value
        ? parseInt(value, 10)
        : initialState.filterWorkerKbnId,
    });
  };

  onChangeFilterDepartments = (value?: string) => {
    this.setState({
      filterDepartmentId: value
        ? parseInt(value, 10)
        : initialState.filterDepartmentId,
    });
  };

  componentDidMount() {
    const { officeId } = this.props;
    // ログインに戻す
    if (!officeId) {
      this.props.history.push('/login');
      return;
    }
    this.handleFetch();
  }

  render() {
    const { classes } = this.props;
    const {
      activities,
      dailyReports,
      workers,
      works,
      products,
      clients,
      workGroups,
      kbns,
      departments,

      startAt,
      endAt,
      dailyReportId,
      dailyReportWorkId,
      workerId,
      workerCode,

      visibleNew,
      visibleEdit,
      from,
      to,
      filterEndAt,
      filterWorkId,
      filterProductId,
      filterClientId,
      filterWorkGroupId,
      filterWorkerKbnId,
      filterDepartmentId,
    } = this.state;

    let filteredActivities = activities;
    if (filterEndAt) {
      filteredActivities = filteredActivities.filter(x => !x.endAt);
    }
    if (filterWorkId) {
      filteredActivities = filteredActivities.filter(
        x => x.workId === filterWorkId
      );
    }
    if (filterProductId) {
      filteredActivities = filteredActivities.filter(
        x => x.productId === filterProductId
      );
    }
    if (filterClientId) {
      filteredActivities = filteredActivities.filter(
        x => x.clientId === filterClientId
      );
    }
    if (filterWorkGroupId) {
      filteredActivities = filteredActivities.filter(
        x => x.workGroupId === filterWorkGroupId
      );
    }
    if (filterWorkerKbnId) {
      filteredActivities = filteredActivities.filter(
        x => x.workerKbnId === filterWorkerKbnId
      );
    }
    if (filterDepartmentId) {
      filteredActivities = filteredActivities.filter(
        x => x.departmentId === filterDepartmentId
      );
    }

    // 従業員コードのバリデーション
    let isWorkerCodeValid = false;
    if (workerCode) {
      const pattern = /^0[0-9]{5}$/g;
      isWorkerCodeValid = pattern.test(workerCode);
    }

    return (
      <>
        <Button
          onClick={() => {
            // モーダルの表示が遅くなるのでawaitさせない
            this.handleFetchDailyReports();
            this.setState({ visibleNew: true });
          }}
          type="primary"
          style={{ marginBottom: 16 }}
        >
          追加
        </Button>
        <form
          noValidate
          className={classes.container}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TextField
            id="date"
            label="開始時刻 (から)"
            type="date"
            value={from}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.onChangeDate('from')}
          />
          <span>〜</span>
          <TextField
            id="date"
            label="開始時刻 (まで)"
            type="date"
            value={to}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.onChangeDate('to')}
          />
          <Button onClick={this.handleFetch}>検索</Button>
        </form>
        <div>
          {/* 検索条件 未終了 */}
          <Checkbox
            checked={filterEndAt}
            onChange={this.onChangeFilterEndAt}
            style={{ marginRight: 5 }}
          >
            未終了
          </Checkbox>
          {/* 検索条件 作業 */}
          <Select
            placeholder="作業"
            value={filterWorkId ? `${filterWorkId}` : undefined}
            onChange={this.onChangeFilterWorks}
            style={{ width: 300, marginBottom: 8 }}
          >
            {works
              .filter(v => !!v.visibleFlag)
              .map(v => (
                <Option key={v.workId} value={`${v.workId}`}>
                  {v.clientId ? `${v.workName} (${v.clientName})` : v.workName}
                </Option>
              ))
              .concat([
                <Option key={0} value={undefined} style={{ color: '#bfbfbf' }}>
                  選択しない
                </Option>,
              ])}
          </Select>
        </div>
        <div>
          {/* 検索条件 製品 */}
          <Select
            placeholder="製品"
            value={filterProductId ? `${filterProductId}` : undefined}
            onChange={this.onChangeFilterProducts}
            style={{ width: 300, marginRight: 8, marginBottom: 8 }}
          >
            {products
              .filter(v => !!v.visibleFlag)
              .map(v => (
                <Option key={v.productId} value={`${v.productId}`}>
                  {v.productName}
                </Option>
              ))
              .concat([
                <Option key={0} value={undefined} style={{ color: '#bfbfbf' }}>
                  選択しない
                </Option>,
              ])}
          </Select>
          {/* 検索条件 取引先 */}
          <Select
            placeholder="取引先"
            value={filterClientId ? `${filterClientId}` : undefined}
            onChange={this.onChangeFilterClients}
            style={{ width: 300, marginRight: 8, marginBottom: 8 }}
          >
            {clients
              .map(v => (
                <Option key={v.clientId} value={`${v.clientId}`}>
                  {v.clientName}
                </Option>
              ))
              .concat([
                <Option key={0} value={undefined} style={{ color: '#bfbfbf' }}>
                  選択しない
                </Option>,
              ])}
          </Select>
        </div>
        <div>
          {/* 検索条件 作業グループ */}
          <Select
            placeholder="作業グループ"
            value={filterWorkGroupId ? `${filterWorkGroupId}` : undefined}
            onChange={this.onChangeFilterWorkGroups}
            style={{ width: 300, marginRight: 8, marginBottom: 8 }}
          >
            {workGroups
              .filter(v => !!v.visibleFlag)
              .map(v => (
                <Option key={v.workGroupId} value={`${v.workGroupId}`}>
                  {v.workGroupName}
                </Option>
              ))
              .concat([
                <Option key={0} value={undefined} style={{ color: '#bfbfbf' }}>
                  選択しない
                </Option>,
              ])}
          </Select>
          {/* 検索条件 従業員区分 */}
          <Select
            placeholder="従業員区分"
            value={filterWorkerKbnId ? `${filterWorkerKbnId}` : undefined}
            onChange={this.onChangeFilterWorkerKbns}
            style={{ width: 300, marginRight: 8, marginBottom: 8 }}
          >
            {kbns
              .filter(v => v.kbnCategory === 'worker')
              .filter(v => !!v.visibleFlag)
              .map(v => (
                <Option key={v.kbnId} value={`${v.kbnId}`}>
                  {v.kbnName}
                </Option>
              ))
              .concat([
                <Option key={0} value={undefined} style={{ color: '#bfbfbf' }}>
                  選択しない
                </Option>,
              ])}
          </Select>
          {/* 検索条件 部門 */}
          <Select
            placeholder="部門"
            value={filterDepartmentId ? `${filterDepartmentId}` : undefined}
            onChange={this.onChangeFilterDepartments}
            style={{ width: 300, marginRight: 8, marginBottom: 8 }}
          >
            {departments
              .filter(v => !!v.visibleFlag)
              .map(v => (
                <Option key={v.departmentId} value={`${v.departmentId}`}>
                  {v.departmentName}
                </Option>
              ))
              .concat([
                <Option key={0} value={undefined} style={{ color: '#bfbfbf' }}>
                  選択しない
                </Option>,
              ])}
          </Select>
        </div>
        <ToolkitProvider
          // @ts-ignore
          keyField="activityId"
          data={filteredActivities}
          columns={this.columns}
          exportCSV={{
            fileName: `作業実績一覧_${from}_${to}.csv`,
            noAutoBOM: false,
          }}
        >
          {(props: any) => (
            <div>
              {/* 検索条件 リセット */}
              <Button
                onClick={() => {
                  this.setState({
                    filterEndAt: initialState.filterEndAt,
                    filterWorkId: initialState.filterWorkId,
                    filterProductId: initialState.filterProductId,
                    filterClientId: initialState.filterClientId,
                    filterWorkGroupId: initialState.filterWorkGroupId,
                    filterWorkerKbnId: initialState.filterWorkerKbnId,
                    filterDepartmentId: initialState.filterDepartmentId,
                  });
                }}
                style={{ marginRight: 8 }}
              >
                絞り込み条件をリセット
              </Button>
              <Button
                type="primary"
                // @ts-ignore
                onClick={() => props.csvProps.onExport()}
                disabled={!activities.length}
              >
                CSVダウンロード
              </Button>
              <hr />
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                defaultSorted={this.defaultSorted}
                rowStyle={{
                  overflowWrap: 'break-word',
                  fontSize: '0.8rem',
                }}
                noDataIndication="該当するデータがありませんでした"
                pagination={paginationFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
        {/* 作業実績 登録 */}
        <Modal
          title="作業実績を追加"
          visible={visibleNew}
          onOk={() => this.handlePost()}
          onCancel={() =>
            this.setState({
              ...initialState,
              activities,
              dailyReports,
              workers,
              works,
              products,
              clients,
              workGroups,
              kbns,
              from,
              to,
              filterEndAt,
              filterWorkId,
              filterProductId,
              filterClientId,
              filterWorkGroupId,
              filterWorkerKbnId,
              filterDepartmentId,
            })
          }
          cancelText="キャンセル"
          okButtonProps={{
            disabled:
              !startAt ||
              !dailyReportId ||
              !dailyReportWorkId ||
              !(workerId || isWorkerCodeValid),
          }}
        >
          <Form>
            <Form.Item style={{ marginBottom: 0 }}>
              <Select
                placeholder="日報"
                value={dailyReportId ? `${dailyReportId}` : undefined}
                style={{ width: '100%' }}
                onChange={this.onChangeDailyReports}
              >
                {dailyReports.map(v => {
                  let label = format(v.dailyReportDate, 'YYYY/MM/DD(dd)', {
                    locale: ja,
                  });
                  label = v.department
                    ? `${label} ${v.department.departmentName}`
                    : label;
                  label =
                    v.department && v.team
                      ? `${label}/${v.team.teamName}`
                      : label;
                  return (
                    <Option key={v.dailyReportId} value={`${v.dailyReportId}`}>
                      {label}
                    </Option>
                  );
                })}
              </Select>
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                value={moment(startAt)}
                onChange={this.onChangeStartAt}
                placeholder="開始時刻"
              />
              <span> 〜 </span>
              <DatePicker
                disabledDate={endValue => {
                  if (endValue) {
                    return isBefore(endValue.toDate(), startAt);
                  }
                  return false;
                }}
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                value={endAt ? moment(endAt) : undefined}
                onChange={this.onChangeEndAt}
                placeholder="終了時刻"
              />
              <Select
                placeholder="作業"
                value={dailyReportWorkId ? `${dailyReportWorkId}` : undefined}
                style={{ width: '100%' }}
                onChange={this.onChangeDailyReportWorks}
              >
                {dailyReportId
                  ? dailyReports
                      .filter(x => x.dailyReportId === dailyReportId)[0]
                      .works.filter(work => !!work.visibleFlag)
                      .map(work => {
                        // 取引先が紐づいていれば作業名と合わせて表示
                        let workName = work.clientId
                          ? `${work.workName} (${work.clientName})`
                          : work.workName;
                        // 製品が紐づいていれば作業名と合わせて表示
                        const productName = work.dailyReportProductId
                          ? dailyReports
                              .filter(x => x.dailyReportId === dailyReportId)[0]
                              .products.filter(
                                product =>
                                  product.dailyReportProductId ===
                                  work.dailyReportProductId
                              )[0].productName
                          : undefined;
                        workName = productName
                          ? `【${productName}】${workName}`
                          : workName;
                        return (
                          <Option
                            key={work.dailyReportWorkId}
                            value={`${work.dailyReportWorkId}`}
                          >
                            {workName}
                          </Option>
                        );
                      })
                  : []}
              </Select>
              <Select
                placeholder="従業員"
                value={workerId ? `${workerId}` : undefined}
                style={{ width: '100%' }}
                onChange={this.onChangeWorkers}
              >
                {workers
                  .filter(v => !!v.visibleFlag)
                  .map(v => (
                    <Option key={v.workerId} value={`${v.workerId}`}>
                      {v.workerName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        {/* 作業実績 編集 */}
        <Modal
          title="作業実績を編集"
          visible={visibleEdit}
          onOk={() => this.handlePut()}
          onCancel={() =>
            this.setState({
              ...initialState,
              activities,
              dailyReports,
              workers,
              works,
              products,
              clients,
              workGroups,
              kbns,
              from,
              to,
              filterEndAt,
              filterWorkId,
              filterProductId,
              filterClientId,
              filterWorkGroupId,
              filterWorkerKbnId,
              filterDepartmentId,
            })
          }
          cancelText="キャンセル"
          okButtonProps={{
            disabled:
              !startAt || !dailyReportId || !dailyReportWorkId || !workerId,
          }}
        >
          <Form>
            <Form.Item style={{ marginBottom: 0 }}>
              <Select
                placeholder="日報"
                value={dailyReportId ? `${dailyReportId}` : undefined}
                style={{ width: '100%' }}
                onChange={this.onChangeDailyReports}
              >
                {dailyReports.map(v => {
                  let label = format(v.dailyReportDate, 'YYYY/MM/DD(dd)', {
                    locale: ja,
                  });
                  label = v.department
                    ? `${label} ${v.department.departmentName}`
                    : label;
                  label =
                    v.department && v.team
                      ? `${label}/${v.team.teamName}`
                      : label;
                  return (
                    <Option key={v.dailyReportId} value={`${v.dailyReportId}`}>
                      {label}
                    </Option>
                  );
                })}
              </Select>
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                value={moment(startAt)}
                onChange={this.onChangeStartAt}
                placeholder="開始時刻"
              />
              <span> 〜 </span>
              <DatePicker
                disabledDate={endValue => {
                  if (endValue) {
                    return isBefore(endValue.toDate(), startAt);
                  }
                  return false;
                }}
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                value={endAt ? moment(endAt) : undefined}
                onChange={this.onChangeEndAt}
                placeholder="終了時刻"
              />
              <Select
                placeholder="作業"
                value={dailyReportWorkId ? `${dailyReportWorkId}` : undefined}
                style={{ width: '100%' }}
                onChange={this.onChangeDailyReportWorks}
              >
                {dailyReportId
                  ? dailyReports
                      .filter(x => x.dailyReportId === dailyReportId)[0]
                      .works.filter(work => !!work.visibleFlag)
                      .map(work => {
                        // 取引先が紐づいていれば作業名と合わせて表示
                        let workName = work.clientId
                          ? `${work.workName} (${work.clientName})`
                          : work.workName;
                        // 製品が紐づいていれば作業名と合わせて表示
                        const productName = work.dailyReportProductId
                          ? dailyReports
                              .filter(x => x.dailyReportId === dailyReportId)[0]
                              .products.filter(
                                product =>
                                  product.dailyReportProductId ===
                                  work.dailyReportProductId
                              )[0].productName
                          : undefined;
                        workName = productName
                          ? `【${productName}】${workName}`
                          : workName;
                        return (
                          <Option
                            key={work.dailyReportWorkId}
                            value={`${work.dailyReportWorkId}`}
                          >
                            {workName}
                          </Option>
                        );
                      })
                  : []}
              </Select>
              <Select
                placeholder="従業員"
                value={workerId ? `${workerId}` : undefined}
                style={{ width: '100%' }}
                onChange={this.onChangeWorkers}
              >
                {workers
                  .filter(v => !!v.visibleFlag)
                  .map(v => (
                    <Option key={v.workerId} value={`${v.workerId}`}>
                      {v.workerName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TableActivity));
