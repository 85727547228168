import * as React from 'react';
import { Modal, Select, Form } from 'antd';
import { CustomInput as Input } from './CustomAntd';

import { Product } from '../types/apiResponse/products.response';

const { Option } = Select;

type E = React.ChangeEvent<HTMLInputElement>;

export interface Props {
  products: Product[];
  productId: number;
  quantity?: number;
  visibleNew: boolean;
  visibleEdit: boolean;
  isNewDuplicated: boolean;
  isEditDuplicated: boolean;
  onChangeProducts: (value: string) => void;
  onChangeQuantity: (e: E) => void;
  onCannelAdd: () => void;
  onCannelEdit: () => void;
  handleOkAdd: () => void;
  handleOkEdit: () => void;
}

const DailyReportAddEditProductModal: React.SFC<Props> = props => {
  const {
    products,
    productId,
    quantity,
    visibleNew,
    visibleEdit,
    isNewDuplicated,
    isEditDuplicated,
    onChangeProducts,
    onChangeQuantity,
    onCannelAdd,
    onCannelEdit,
    handleOkAdd,
    handleOkEdit,
  } = props;
  return (
    <>
      <Modal
        title="日報:製品の追加"
        visible={visibleNew}
        onOk={handleOkAdd}
        onCancel={onCannelAdd}
        cancelText="キャンセル"
        okButtonProps={{ disabled: !productId || isNewDuplicated }}
      >
        <Form>
          <Form.Item
            validateStatus={isNewDuplicated ? 'error' : undefined}
            help={
              isEditDuplicated
                ? '既に登録されている内容は登録できません'
                : undefined
            }
          >
            <Select
              placeholder="製品"
              value={productId ? `${productId}` : undefined}
              style={{ width: '100%' }}
              onChange={onChangeProducts}
            >
              {products
                .filter(v => !!v.visibleFlag)
                .map(v => (
                  <Option key={v.productId} value={`${v.productId}`}>
                    {`${v.productName} (${v.unitPrice}円)`}
                  </Option>
                ))}
            </Select>
            <Input
              placeholder={
                products.filter(x => x.productId === productId).length
                  ? `数量(単位 : ${
                      products.filter(x => x.productId === productId)[0]
                        .quantityUnit
                    })`
                  : `数量`
              }
              value={quantity}
              type="number"
              onChange={onChangeQuantity}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* 日報:製品の編集 */}
      <Modal
        title="日報:製品の編集"
        visible={visibleEdit}
        onOk={handleOkEdit}
        onCancel={onCannelEdit}
        cancelText="キャンセル"
        okButtonProps={{ disabled: !productId || isEditDuplicated }}
      >
        <Form>
          <Form.Item
            validateStatus={isEditDuplicated ? 'error' : undefined}
            help={
              isEditDuplicated
                ? '既に登録されている内容は登録できません'
                : undefined
            }
          >
            <Select
              placeholder="製品"
              value={productId ? `${productId}` : undefined}
              style={{ width: '100%' }}
              onChange={onChangeProducts}
            >
              {products
                .filter(v => !!v.visibleFlag)
                .map(v => (
                  <Option key={v.productId} value={`${v.productId}`}>
                    {`${v.productName} (${v.unitPrice}円)`}
                  </Option>
                ))}
            </Select>
            <Input
              placeholder={
                products.filter(x => x.productId === productId).length
                  ? `数量(単位 : ${
                      products.filter(x => x.productId === productId)[0]
                        .quantityUnit
                    })`
                  : `数量`
              }
              value={quantity}
              type="number"
              onChange={onChangeQuantity}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DailyReportAddEditProductModal;
